@import '../mixin.scss';

@keyframes sideImageAnimation_round {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes labelAnimation_round {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes inputAnimation_round {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  10% {

    opacity: 0;
    transform: scale(.9);
  }

  35% {
    opacity: 1;
    transform: scale(1.05);
  }

  45% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.formstyle__round {

  //error
  .block_error {
    div {
      border-radius: 32px;
      animation: roundError 0.25s ease-in-out;
    }
  }

  @keyframes roundError {
    0% {
      margin-bottom: 20px;
    }

    25% {
      margin-bottom: 0;
    }

    100% {
      margin-bottom: 0;
    }
  }

  // fields styles
  .item__voucher{
    border-radius: 20px;
    border: none !important;
  }
  .item__legal{
    border-radius: 20px;
    border: none !important;
  }
  .option__item--slider{
    border: 2px solid;
    border-radius: 20px;
    transition: all .2s;
    opacity: .8;
    &:hover, &:focus{
      transition: all .2s;
      opacity: 1;
    }
  }
  
  .option_item{
    padding: var(--xxs-spacing);
    margin-bottom: var(--xxs-spacing);
    border-radius: 40px;
    transition: all .2s;
    opacity: .8;
    border: 2px solid transparent;
    
    &:hover, &:focus{
      transition: all .2s;
      opacity: 1;
      border: 2px solid;
    }
  }
  .option_item_selected{
    opacity: 1;
  }
  .field__type_scale_frame {
    gap: 0;
    border-radius: 32px;
    overflow: hidden;
  }

  .field__type_scale_empty {
    opacity: 1;
    border: 3px solid transparent;
    transition: all .2s;
    // border-radius: 32px;
    border-radius: 0;
    opacity: .5;

    // background-color: transparent !important;
    &:hover {
      transition: all .2s;
      opacity: 1;
    }
  }

  .field__type_scale_selected {
    transition: all .2s;
    border: none;
    border-radius: 0;
    position: relative;

    i {
      display: flex;
      position: absolute;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
      top: 0;
      left: 0;

    }

    span {
      display: none !important;
    }

    &:first-of-type {
      border-radius: 32px 0 0 32px;
    }
  }

  .field__type_rating {
    svg {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding: 4px;
      background-color: transparent !important;
      transition: all .25s;

      &:hover {
        transition: all .25s;
      }
    }

    .star-empty {
      transition: all .5s;
      box-shadow: 0 0 0;
      transition: all .25s;
      border: none;
      box-shadow: 4px 4px 0 transparent;
      opacity: .5;

      &:hover {
        transition: all .15s;
        opacity: 1;
      }
    }
  }

  .field__type_media {
    border-radius: 28px;
  }

  .field__type_pdf {
    border-radius: 200px;
    max-width: max-content;
    border: none;
    box-shadow: none;
    transition: all .5s;

    &:hover {
      border: 2px solid;
    }
  }

  .field__input__number {
    .field__input__number__controls {
      border-radius: 8px;

      div {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        border: 2px solid rgba(0, 0, 0, 0.1) !important;
        border-bottom: 4px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0 200px 200px 0;
        transition: all .5s;

        &:hover,
        &:focus {
          opacity: 1;
          outline: 2px solid inherit;
          transition: all .5s;
          border-color: inherit !important;

        }

        &:first-of-type {
          border-radius: 200px 0 0 200px;
          border-right: 0 !important;
          margin-right: -2px;

          &:hover,
          &:focus {
            border-right: 2px solid !important;
          }
        }
      }
    }
  }

  // button styles
  .footer__item {
    button.previous {
      background: transparent;
      width: min-content;
      transition: all .2s;
      padding: 16px 16px;
      opacity: 0.7;
      border: 0;
      cursor: pointer;
      user-select: none;

      &:hover {
        transition: all .2s;
        transform: scale(1);
        opacity: 1;
        box-shadow: none;
      }
    }

    .button--submit {}
  }





  .button--submit:hover {}



  .button--submit,
  .button--next {
    transition: all .2s;
    // box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    appearance: button;
    border-radius: 200px;
    // border-width: 0 0 6px;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;

    &:hover {
      transition: all .25s;
      filter: brightness(1.1);
      border: solid transparent;
      border-width: 0 0;
      -webkit-filter: brightness(1.1);
      transform: scale(1.02);
    }
  }


  // media on the left

  .container__item--content--animation-title {}



  &.formtype__classic {
    .container__item--media {
      div {
        max-height: 380px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 16px 16px;

      }
    }
  }
  



  

  
  &.formtype__slideshow {
    

    .container__item--media{
      flex: 1;
    }
    .container__item--media--inner {
      @include devices(desktoponly) {
        border-style: solid;
        // border-width: 1vw;
        // height: calc(100vh - 2vw);
        min-height: unset;
        background-color: inherit;

        img,
        video {
          height: calc(100vh - 2vw);
          border-radius: var(--s-radius);
          min-height: unset;
        }

      }
      @include devices(tabletanddesktop) {
        min-height: 100vh;
        box-sizing: border-box;
        padding: 1vw;
        overflow: hidden;
        box-sizing: border-box;

        img,
        video {
           min-height: calc(100vh - 2vw);

            border-radius: var(--s-radius);
        }
    }
    // @include devices(desktop){
    //   padding-left: 0;
    //   padding-right: 0;
    // }
    }
    

    .container__item--media {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      @include devices(mobileandtablet){
        border-radius: 0;
      }

      div {
        width: 100%;
        overflow: hidden;
        // padding: 16px 0 28px 16px;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        @include devices(mobileandtablet){
        border-radius: 0;
        }
      }
    }
  }

  .signature__area {
    backdrop-filter: blur(16px);
  }

  .signature-canvas {
    transition: all .5s;
    border-radius: 32px;

    &:hover,
    &:focus {
      transition: all .5s;
      opacity: 1;
    }
  }

  //fileuploader
  .dropzone {
    // border: 2px dashed;
    opacity: .5;

    &:hover,
    &:focus {
      transition: all .5s;
      opacity: 1;
      filter: brightness(.8);
    }
  }

  // datepicker
  .interactive_block {
    .react-datepicker-wrapper {
      width: 100%;
      position: relative;

      svg {
        position: absolute;
        top: 8px;
        right: 0;
      }
    }

    .react-datepicker__day {
      border-bottom: 3px solid;
      width: max-content !important;
      margin: 4px auto;
      min-width: 32px !important;
    }

    .react-datepicker__day:hover {
      border-radius: 8px;
    }

    .field__input,
    .react-datepicker-wrapper input {
      padding: 16px 20px;
      display: flex;
      border: none;
      // margin-bottom: 32px;
      transition: .75s all;
      border-radius: 28px;
      backdrop-filter: blur(16px);

      &:focus {
        opacity: 1;
        transition: .75s all;
      }

      &::placeholder {
        opacity: .75;
        color: inherit;
      }
    }
  }

  .input__icon {
    svg {
      padding-right: 18px;
    }
  }

  .carousel-slider {
    .option__item--indicator--selected {
      width: 40px;
      border-radius: 30px;
    }
  }
}

//for Editor only
.page__editor {
  .formstyle__round {
    @include devices(desktoponly) {
      .container__item--media--inner {
        // height: calc(100vh - 2vw - 19px);
        min-height: unset;
        height: 99%;
        padding: 0;

        img,
        video {
          // height: calc(100vh - 2vw - 19px);
          height: 100%;
          border-radius: var(--s-radius);
          min-height: unset;
        }
      }
    }
    .container__item{
      @include devices(mobile){
        padding: 0;
      }
    }
  }
  
}

.page__form{
  &.formstyle__round {
  &.formtype__slideshow {
    .container__item--media{
      justify-content: unset;
      align-items: unset;
      img, video{
    min-height: unset;

      }
    }
  }
}

}

.container__item--media--loader {
  width: 100%;
  background-color: inherit;
}

.formstyle__round.page__editor.formtype__slideshow{

    .container__item--media--inner {
      @include devices(desktop) {
          border-width: 1vw !important;
      }}
}