//Breakpoint

$desktop_screen: 1024px;
$mobile_screen: 640px;
$tiny_mobile_screen: 400px;

$small_desktop_screen: 1400px;

$medium_tablet_screen: 992px;
$large_tablet_screen: 1199px;


@mixin devices ($breakpoint) {
  //the name of the mixin is devices

  $small_tablet_start: $mobile_screen +1;
  $small_tablet_end: $medium_tablet_screen - 1;
  $medium_tablet_end: $medium_tablet_screen - 1;
  $large_tablet_end: $desktop_screen - 1;

  @if $breakpoint == desktop {
    @media only screen and (min-width: $desktop_screen) {
      @content;
    }
  }

  @if $breakpoint ==desktoponly {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  }

   //Generic practically all the tablets
   @if $breakpoint == smalldesktop {
    @media only screen and (min-width: $mobile_screen) and (max-width: $small_desktop_screen ) {
      @content;
    }
  }

  //Generic practically all the tablets
  @if $breakpoint ==tabletanddesktop {
    @media only screen and (min-width:  $small_tablet_start) {
      @content;
    }
  }

  //More specific tablet sizes
  @if $breakpoint ==largetablet {
    @media only screen and (min-width: $large_tablet_screen) and (max-width: $large_tablet_end) {
      @content;
    }
  }

  @if $breakpoint ==mediumtablet {
    @media only screen and (min-width: $medium_tablet_screen) and (max-width: $medium_tablet_end) {
      @content;
    }
  }


  @if $breakpoint == tablet {
    @media only screen and (min-width: $small_tablet_start) and (max-width: $desktop_screen) {
      @content;
    }
  }

  //Mobile screen and tablets
  @if $breakpoint == mobileandtablet {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  }

  @if $breakpoint ==mobile {
    @media only screen and (max-width: $mobile_screen) {
      @content;
    }
  }
  @if $breakpoint ==tinymobile {
    @media only screen and (max-width: $tiny_mobile_screen) {
      @content;
    }
  }
  
  @if $breakpoint == safari {
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @content;
      }
    }
  




}