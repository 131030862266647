@import '../mixin.scss';
.page{
    position: relative;
    background-color: var(--bg-color);
    min-height: 100vh;   
}
.template__meta_info{
    display: grid;
    padding-top: var(--xs-spacing);
    font-size: var(--s-text);
    font-size: var(--m-text);
    row-gap: var(--xxs-spacing);
    align-items: center;

    @include devices(desktop){
        font-size: var(--s-text);
        column-gap: var(--xs-spacing);
        grid-template-columns: max-content max-content;
    }
    label,a{
        display: grid;
        justify-content: flex-start;
        align-items: center;

    }
    label {
        grid-template-columns: max-content auto;
        column-gap: var(--xxs-spacing);
        cursor: pointer;
        svg{
            width: 24px;
            color:var(--grey-60)
        }
    }
    .template_rating{
        grid-template-columns: repeat(5, max-content);

        label{
            display: none;
        }
        @include devices(desktop){
        grid-template-columns: repeat(5, max-content);
        label{
            padding-left: var(--xxs-spacing);
        }
        }
        button{
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            margin-top: 0;
            &:hover{
                svg{
                color:var(--primary-color-1000)
                }
            }
            svg{
                width: 32px;
                color:var(--grey-100)
            }
            @include devices(desktop){
                width: 24px;
                svg{
                    width: 24px;
                    color:var(--grey-100)
                }
            }

        }

    }

}
.template_image {
    height: 600px;
    overflow: hidden;
    z-index: 3;
    border-radius: var(--s-radius);
    box-shadow: var(--l-shadow);
    box-sizing: border-box;

    iframe {
        width:100%;
        height: 100%;
        border:none;
        box-sizing: border-box;
    }
}
.template_preview__container{
    width: 100%;
    .recomendations__wrapper{
        background-color: var(--grey-100);
        padding: var(--m-spacing);

        @include devices(desktop){
            padding: var(--m-spacing) var(--l-spacing);
        }
        h2 {
            color:var(--white-36);
            font-weight: normal;
            font-size: var(--m-text);
            padding-bottom: var(--s-spacing);
            text-transform: capitalize;
        }
    }
    .recomendations{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: var(--s-spacing);
        @include devices(desktop){
        grid-template-columns: repeat(5,1fr);
        }
    }
    .recomendation{
        gap: var(--xxs-spacing);
        display: grid;
        grid-template-rows: max-content max-content;
        width: 100%;
        box-sizing: border-box;
        h3{
            font-size: var(--xs-text);
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.5em;
            text-overflow: ellipsis;
            color: var(--white-100);
            font-weight: bold;
            transition: all .5s;
            position: relative;
            transition: opacity 1s;

            &:after{
                content: '';
                position: absolute;
                top:8px;
                left: 0;
                transform: translateY(-50%);
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 6px solid var(--primary-color-1000);
                opacity: 0;
                transition: all .5s;
                width: 0px;
            }
        }
        img{
            border-radius: var(--xs-radius);
            border:none;
            width: 100%;
            border: 2px solid transparent;
            transition: all .5s;
        }

        &:hover{
            img {
                border-color: var(--white-100);
                transition: all .5s;
            }
            h3{
                padding-left: var(--xs-spacing);
                transition: all .5s;

                &:after{
                    opacity: 1;
                    transition: all .25s;
                }
            }
        }
    }
    .template_preview{
        position: relative;
        z-index: 0;
        box-sizing: border-box;
        padding:  var(--m-spacing) var(--m-spacing);
        // ask @Sally what I'm doing wrong here
        margin: auto;
        width:100vw;
        
        h2{
            font-size: 52px;
            letter-spacing: -5px;
            position: absolute;
            left: -32px;
            top: 120px;
            transform: rotate(90deg);
            animation-name: fadeInCategory;
            animation-duration: .6s;
            z-index: 3;
            text-transform: capitalize;
   

        }

        .colors{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            height: 72px;
            column-gap: 16px;
            width: 240px;
            top: 632px;
            right: 20px;

            position: absolute;
            li{
                background-color: black;;
                font-size: var(--xl-text);
                display: grid;
                align-items: center;
                justify-content: center;
                position: relative;
            }
            .form_style_info__colors_font__screen {
                position: absolute;
                top:0;
                left: 0;
                height: 100%;
                width: 100%;
                display: block;
                z-index: 1;
                opacity: 0.5;
            }
        }
        .bar {
            display: block;
            position: absolute;
            left: 20px;
            bottom: 80px;
            width: 80px;
            height: 20px;
            background:var(--grey-100);
        }
        @include devices (desktop){
            padding: var(--m-spacing) var(--l-spacing) var(--m-spacing) var(--xl-spacing);
            width:80vw;
            max-width: 1400px;
            h2{
                font-size: var(--xxl-text);
                left: 20px;
                top: 220px;
                transform: rotate(90deg);
            }
            .colors{
                height: 108px;
                column-gap: 20px;
                width: 420px;
                // top: 620px;
                top:20px;
                right: 0;
                li{
                    box-shadow:var(--l-shadow);
                }
            }
             .bar{
                left: 56px;
                top: 560px;
                width: 80px;
                height: 16px;
             }       

        }
    





    }
    .template__details{
        width:80vw;
        max-width: 880px;
        margin: auto;
        padding-bottom: 56px;
        p {
            display: block;
            line-height: 1.5em;
            font-size: 16px;
            color: var(--grey-60);
            padding-top: var(--xs-spacing);
        }
        h1{
            font-size: var(--xl-text);
            line-height: var(--xl-text);
            letter-spacing: -2px;
            padding-top:var(--m-spacing);
        }
        h2{
            font-size: var(--s-text);
            padding:0;
            padding-top: var(--m-spacing);
        }
        @keyframes fadeInCategory {
            0%   {opacity:0; left:-80px}
            100%   {opacity:1; left:-32px}
        }
        .template_description{
            padding: var(--s-spacing) 0;
            h2{
                padding: var(--xs-spacing) 0 0;
                font-size: var(--l-text);
                letter-spacing: -1px;
            }
            hr {
                border: 1px solid var(--grey-08);
                margin-top: var(--s-spacing);
            }p,li{
                color:var(--grey-60);
                letter-spacing: -.5px;
                em{
                    font-weight: bold;
                    color:var(--grey-100);
                    font-style: normal;
                }
            }
            li{
                margin-left: 20px;
            }
            ul{
                li{
                    list-style:square;
                }
            }
            ol{
                margin-top: var(--s-spacing);

                li{
                    margin-top: var(--xxs-spacing);
                    list-style: decimal;
                }
            }
        }
        .template_info{
            display: grid;
            margin-top: var(--xxs-spacing);
            padding: var(--xxs-spacing) var(--xs-spacing);
            background-color: var(--grey-03);

            li {
                width: 100%;
                display: grid;
                grid-template-columns: 2fr auto;
                color:var(--grey-100);
                font-size: var(--s-text);
                padding: var(--xxs-spacing) 0;
                border-bottom: 2px solid var(--grey-08);
                &:last-child{
                    border-bottom: none;
    
                }
                text-transform: capitalize;
    
    
            }
            .style_label{
                color:var(--grey-60);
            }
        }
    }

}

.template_search__items{
    display: grid;
    // width: 100vw;
    // padding: var(--xs-spacing) var(--m-spacing);
    box-sizing: border-box;
    gap: var(--s-spacing);
    @include devices(desktop){
        // padding: var(--m-spacing) var(--l-spacing);
    }
    @include devices(desktop){
        grid-template-columns: repeat(3,1fr);
        gap: var(--sm-spacing);
    }
    .template_search__item{
            gap: var(--xxs-spacing);
            padding: var(--s-spacing) var(--s-spacing);
            display: grid;
            cursor: pointer;
            grid-template-rows: max-content auto;
            width: 100%;
            gap: var(--xxxs-spacing);
            box-sizing: border-box;
            color: var(--grey-60);
            align-items: flex-start;
            border-radius: var(--xs-radius);
            overflow: hidden;
            position: relative;
            background-color: var(--grey-03);
            user-select: none;
            .template_search__item_background{
                filter:blur(48px);
                -webkit-filter:blur(48px);
                background-position-y: center;
                background-position-x: center;
                background-size: cover;
                height: 100%;
                width: 100%;
                z-index: 0;
                position: absolute;
                opacity: .25;
                
            }
            h3{
                font-size: var(--s-text);
                // white-space: nowrap;
                padding-bottom: var(--xxs-spacing);
                // overflow: hidden;
                line-height: 1.5;
                letter-spacing: -.5px;
                text-overflow: ellipsis;
                color: var(--grey-60);
                font-weight: bold;
                transition: all .5s;
                position: relative;
                transition: opacity 1s;
                margin: 0;
                width: 100%;
                &:after{
                    content: '';
                    position: absolute;
                    top:8px;
                    left: 0;
                    transform: translateY(-50%);
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    border-left: 6px solid var(--primary-color-1000);
                    opacity: 0;
                    transition: all .5s;
                    width: 0px;
                }
            }
            .template_preview_image{
                border-radius: var(--xs-radius);
                border:none;
                width: 100%;
                z-index: 1;
                transition: all .5s;
                margin-top: auto;

            }
            article{
                display: grid;
                grid-template-columns: max-content max-content;
                font-size: var(--xs-text);
                color: var(--grey-30);
                .rater{
                    svg{
                        width:18px;
                        &:hover{
                            fill:var(--primary-color-1000);
                            color:var(--primary-color-1000);
                        }
                    }
                }
                label{
                    display: flex;
                    padding-right: var(--xs-spacing);
                    align-items: center;
                    cursor: pointer;

                    i {
                        font-style: normal;
                        padding-left: var(--xxxs-spacing);
                    }
                }

            }
            transition: all .5s;

            &:hover{
                transition: all .5s;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 25px 20px -20px;
                img {
                    border-color: var(--white-100);
                    transition: border .5s;
                }
                h3{
                    padding-left: var(--xs-spacing);
                    transition: all .5s;
                    color: var(--grey-100);
    
                    &:after{
                        opacity: 1;
                        transition: all .25s;
                    }
                }
            } 
    }

}
.template_title{
    padding: var(--sm-spacing) var(--m-spacing);
    padding-bottom: 0;
    row-gap: var(--xs-spacing);
    display: grid;
    h1{
        letter-spacing: -2px;
        font-size: var(--xl-text);
        line-height: var(--xl-text);
        margin: none;
    }
    @include devices(desktop) {
        padding: var(--m-spacing) var(--l-spacing);
        padding-bottom: 0;
        display: grid;    
    }
    p {
        color: var(--grey-60);
    }
}
.template_subtitle{
    letter-spacing: -1px;
    font-size: var(--s-text);
}
.template_category_header{
    padding: var(--sm-spacing) var(--m-spacing);
    display: grid;
    box-sizing: border-box;
    row-gap: var(--xxs-spacing);
    align-items: center;
    background-color: var(--grey-100);
    .category_title{
        letter-spacing: -2px;
        line-height: var(--xl-text);
        text-transform: capitalize;
        margin: 0;
        padding:0;
        position: relative;
        .highlight {
            position: absolute;
            top: 4px;
            left: 0;
            height: var(--m-spacing);
            width: 15%;
            z-index: 1;
            opacity: 0.5;
        }
        h1{
            font-size: var(--xl-text);
            color: var(--white-100);
            z-index: 3;
            position: relative;
        }
    }
    h2 {
        color: var(--white-50);
        font-weight: normal;
        letter-spacing: -.5px;
        font-size: var(--s-text);
        line-height: var(--l-text);
        padding:var(--s-spacing) 0;
    }
    p {
        color: var(--grey-60);
        font-weight: normal;
        letter-spacing: -.75px;
        font-size: var(--s-text);
        margin: 0;
        padding:0;
    }
    a {
        color: var(--white-50);
        position: relative;
        font-weight: bold;
        padding-left: var(--xs-spacing);
        transition: all .5s ease-in-out;

        &:after{
            content: '';
            position: absolute;
            top:8px;
            left: 0;
            transform: translateY(-50%);
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 6px solid var(--primary-color-1000);
            opacity: 1;
            transition: all .5s ease-in-out;
            width: 0px;
        }
        &:hover{
            color: var(--primary-color-1000);
            transition: all .5s ease-in-out;
            &:after{
                left: 4px;
                transition: all .5s ease-in-out;
            }
        }
    }
    @include devices(desktop) {
        padding: var(--m-spacing) var(--l-spacing);
    }
}
.search_area{
    width: 100%;
    padding: var(--xs-spacing) var(--m-spacing);
    box-sizing: border-box;
    display: grid;
    row-gap: var(--xs-spacing);
    @include devices(desktop){
        padding: var(--m-spacing) var(--l-spacing) 0 var(--l-spacing);
    }
}
.template_search__wrapper{

    box-sizing: border-box;
    .template_search{
        display: grid;
        column-gap: var(--xxs-spacing);
        padding-bottom: var(--m-spacing);
        position: relative;
        a{
            color: var(--grey-color-100);
            background: transparent;
            border: none;
            cursor: pointer;
            position: absolute;
            right: 0;
            top:12px;
            width:40px;
            transition: all .5s ease-in-out;
            // border-left: 2px solid var(--grey-08);
            border-radius: 0;   

            height: 32px;
            svg {
                width: 32px;
                height: 32px;
            }
            &:hover {
                color: var(--primary-color-1000);
                transition: all .5s ease-in-out;
            }
        }
    }

    input {
        width:100%;
        font-size: var(--s-text);
        font-family: "Archia";   //@Sally should I remove this? and add input to App.scss instead?
        padding: var(--s-spacing);
        border:none;
        border-radius: var(--s-radius);
        box-shadow: var(--s-shadow);
        background-color: var(--white-100);
    }

}
.search_emptyState{
    margin: auto;
    color:var(--grey-60);
    text-align: center;
    padding: var(--l-spacing) var(--m-spacing);
    h1{
        font-size: var(--s-text);
        letter-spacing: -1px;
        padding-bottom: 12px;
        color:var(--grey-100);
    }
    p {
        font-size: var(--xs-text);
        letter-spacing: -.5px;
    }
    svg{
        height: 128px;
        padding-bottom: var(--s-spacing);
    }
}

.template_category{
    text-transform: capitalize;
    font-size: var(--xs-text);
    letter-spacing: -.5px;
    margin-top: var(--m-spacing);
    background-color: var(--grey-100);
    padding: var(--s-spacing) var(--m-spacing);
    white-space: pre-line;
    h2{
        color:var(--white-100);
        font-size: var(--s-text);
        padding-bottom: var(--s-spacing);
        white-space: pre-line;
    }

    button {
        background-color: transparent;
        font-size: var(--xs-text);
        border:none;
        color: var(--white-36);
        font-family: 'Archia';
        margin-top: var(--s-spacing);
        padding-left: var(--xs-spacing);
        position: relative;
        cursor: pointer;
        width: max-content;
        &:after{
            content: '';
            position: absolute;
            bottom:0;
            left: 0;
            transform: translateY(-50%);
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 6px solid var(--primary-color-1000);
            transition: all .5s;
            width: 0px;
        }
        &:hover{
            transition: all .5s;
            color: var(--white-100);
            &:after{
                left: 4px;
            }
        }
    }
    @include devices(desktop){
        padding: var(--m-spacing) var(--l-spacing);
    }
    .template_category_items{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--xs-spacing);
        @include devices(desktop){
            grid-template-columns: repeat(6, 1fr);
        }

        a{
            color:var(--white-100);
            background: rgba($color: #fff, $alpha: .05);
            padding: 0 var(--xs-spacing);
            border-radius: var(--xxs-spacing);
            min-height: 56px;
            transition: all .5s;
            border:2px solid rgba($color: #fff, $alpha: .05);;
            &:hover{
                background: var(--primary-color-20);
                color: var(--white-100);
                transition: all .5s;
                border:2px solid var(--primary-color-1000);
            }
        }
    }
}

.fieldTypesItems{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 20px;
    font-size: var(--xs-text);
    padding-top: var(--xs-spacing);
    @include devices(mobile){
        overflow: auto;
    }
    .fieldTypesItem {
        padding:var(--xxs-spacing);
        color:var(--grey-30);
        display: grid;
        border:2px solid var(--grey-08);
        gap: var(--s-spacing);
        border-radius: var(--s-radius);

        
        .type{
            display: grid;
            column-gap: var(--xxs-spacing);
            grid-template-columns: max-content auto;
            align-items: center;
        }
        h3{
            color:var(--grey-60);
            padding: 0;
            margin:0;
            font-size: var(--s-text);
            text-transform: capitalize;
            padding-bottom: var(--xxxs-spacing);
        }
        svg{
            width: 32px;
            height: 32px;
            padding:var(--xs-spacing);
            border-radius: var(--s-radius);
            path{
                fill: var(--grey-60);
            }
            background-color: var(--grey-08);
        }
    }
}
.cta{

  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
  width: max-content;
  margin-top: 20px;
  border: 4px solid transparent;

&:disabled {
  pointer-events: none;
}

&:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    transform: translateY(-2px);
    border: 4px solid var(--white-08) ;
}

&:active {
  box-shadow: none;
  transform: translateY(0);
}
}
