@import '../mixin.scss';
@keyframes scrollIn {
  0%{top:0; opacity:1}
  10%{top:0; opacity:1}
  15%{top:-88px}
  45%{top:-88px}
  50%{top:-176px}
  70%{top:-176px}
  75%{top:-420px}
  85%{top:-420px; opacity:1}
  100%{top:-420px; opacity:0}
}
@keyframes slideIn {
  0% {
    left: 0;
    opacity: 0;
  }
  5%{
    opacity: 1;
  }
  25% {
    left: 0;
  }
  33% {
    left: -100%;
  }

  62% {
    left: -100%;
  }
  70% {
    left: -200%;

  }
  90% {
    left: -200%;
    opacity: 1;
  }
  100%{
    opacity: 0;
    left: -200%;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  58%{
    opacity:1;
  }
  62% {
    opacity: 0;
  }
  68% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(227, 221, 215, 0.8);
  backdrop-filter: blur(16px);
  display: flex;
  justify-content: center;
  z-index: 2;
  transition: all 1s;
  align-items: center;
  align-items: flex-start;
  @include devices(mobile){
    position: fixed;
    z-index: 1;
  }

  .modal_overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left: 0;
    z-index: 2;
  }

  .modal_content {
    background-color: #fff;
    width: 84%;
    z-index: 3;
    box-shadow: rgba(0, 0, 0, 0.26) 0px 20px 46px 0px;
    border-radius: var(--s-radius);
    display: grid;
    height: fit-content;
    margin: var(--m-spacing) 0;
    grid-template-columns: 1fr;
    grid-template-areas: "mockuparea" "form";
    
    @include devices(mobile){
      min-height: 432px;
    }

    @include devices(desktop){
      // width: 72%;
      max-width: var(--l-block);
      margin: var(--l-spacing) 0;
      grid-template-columns: 5fr 4fr;
      grid-template-areas: "mockuparea form";
    }
    &.modal_content_small{
      display: flex;
      flex-direction: column;
      max-width: 580px;
      min-height: 432px;
    }
    .formTypeSelect{
      li{
        padding-left: 32px;
      }
    }
    .image {
      background-color: var(--bg-color--20);
      background-image: linear-gradient(45deg, rgba(114, 73, 22, 0.36) 0%, rgba(114, 73, 22, 0) 100%);
      height: 100%;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: "mockup"
                            "title";
      border-radius: var(--s-radius) var(--s-radius) 0 0;
    

      @include devices(desktop){
        display: flex;
        border-radius: var(--s-radius) 0 0 var(--s-radius);
        flex-direction: column-reverse;
        align-items: right;
        justify-content: center;
        grid-template-areas: "mockup"
                            "title";
        grid-area: mockuparea;
      }
      .title{
        grid-area: title;
        letter-spacing: -.5px;
        text-align: center;
        width: 80%;
        font-size: var(--xs-text);
        line-height: 1.5em;
        margin:auto;
        // color:var(--white-100);
        padding-bottom: var(--m-spacing);
        @include devices(desktop){
          margin-top: 0;
          padding-bottom: 0;
          // position: absolute;
          // bottom: 72px;
          // padding-bottom: 0;
          // width: 65%;
          // grid-area: none;
        

        }
        strong{
          font-size: var(--s-text);
          padding-bottom: 8px;
          // color:var(--white-100);
          display: block;
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .mockup{
        width: 120%;
        grid-area: mockup;
        height: 275px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 90px;
        border: 8px solid var(--bg-color-20);
        margin: 0 auto;
        border-radius: var(--s-radius);
        overflow: hidden;
        position: relative;
        left: -28px;
        transform: scale(0.7);
        @include devices(desktop){
          transform: unset;
          margin: auto;
          left: unset;
          // top:-32px;
          width: 80%;
        }
        .mockup__button__slideshow{
          background: var(--white-100);
          color: var(--grey-100);
          width: max-content;
          position: absolute;
          bottom: 12px;
          padding: 8px 12px;
          right: 12px;
          border-radius: 8px;
          font-size: 8px;
          font-weight: bold;
          display: block;
        }
        .mockup__button__classic{
          background: var(--white-100);
          color: #2B3467;
          width: max-content;
          position: absolute;
          padding: 8px;
          left: 76px;
          font-size: 8px;
          font-weight: bold;
          display: block;
        }
        strong, div, p{
          // color: var(--white-100);
          font-size: 8px;

        }

      }
      .mockup_classicframes{
        animation: scrollIn;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        position: relative;
        .mockup_block__classic{
          img {
            width: 100%;
            padding: 20px 0;
          }
          h1{
            padding-top:8px;
            // color:var(--white-100);
          }

          .mockup__question{
            margin:auto;
            width: 60%;
            font-size: 8px;
            padding-top: 24px;
            
            .mockup__input{
              border-bottom: 2px solid var(--white-08);
              padding: 8px 0;
              color: var(--white-36);
              margin-top: 8px;
            }
          ul {
            display: grid;
            grid-template-columns: repeat(4,1fr);
            padding: 8px 0;
            box-sizing: border-box;
            font-size: 5px;
            gap:4px;
            li {
              border: 1px solid rgba(255, 255, 255, 0.1);
              padding: 12px 4px 4px;
              border-radius: 4px;

            }
          }
        }
        }
      }
      .mockup_slideshowframes{
        position: relative;
        animation: slideIn;
        animation-duration: 11s;
        animation-iteration-count: infinite;
        display: grid;
        grid-template-columns: repeat(3,100%);
        position: relative;

        .mockup_block__slideshow{
          .mockup__question{
            margin:auto;
            width: 80%;
            font-size: 8px;
            .mockup__input{
              border-bottom: 2px solid var(--white-08);
              padding: 8px 0;
              // color: var(--white-36);
              margin-top: 8px;
            }
          ul {
            display: grid;
            grid-template-columns: repeat(4,1fr);
            padding: 8px 0;
            box-sizing: border-box;
            font-size: 5px;
            gap:4px;
            li {
              border: 1px solid rgba(255, 255, 255, 0.1);
              padding: 12px 4px 4px;
              border-radius: 4px;
            }
          }
        }
        }
        .mockup_block__slideshow{
          display: grid;
          grid-template-columns: 1fr 1fr;
          .mockup__image{
            animation: fadeInOut;
            animation-duration: 11s;
            animation-iteration-count: infinite;
            box-sizing: border-box;
            overflow: hidden;
            img {
              height: 276px;
            }
          }
        }
      }
    }
    form {
      box-sizing: border-box;
      grid-area: form;
      padding: var(--sm-spacing);
      @include devices(desktop){
        min-height: var(--modal-height);
        // padding: 52px 48px;
        padding: var(--sm-spacing);

      }
    }

    label {
      justify-content: flex-start;
      font-size: var(--s-text);
      color: var(--grey-60);
      letter-spacing: -0.5px;
      font-weight: bold;
      padding: var(--s-spacing) 0 var(--xxs-spacing);
      display: grid;
  
      i {
        color: var(--red-100);
      }
    }

    select,
    input {
      width: 100%;
      padding: var(--s-spacing);
      background-color: var(--grey-08);
      color: var(--grey-60);
      border-radius: 12px;
      font-size: var(--s-text);

    }
    input:focus{
      border: 2px solid var(--grey-100);
    }
    input::placeholder {
      color: var(--grey-30);
    }

  }

}

.pepper_modal{

  .modal_content{
    background-color: transparent;
    grid-template-columns: unset;
    grid-template-areas: unset;
    @include devices(desktop){
      min-height: var(--modal-height);
    }
    input{
      width: unset;
    }
  }
}

.themes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--xs-spacing) !important;
  padding: var(--m-spacing) 0;
  box-sizing: border-box;
  @include devices(desktop){
    grid-template-columns: repeat(4, 1fr);
  }
  .active{
    border: 2px solid var(--grey-100);
  }
  li {
    position: relative;
    display: grid;
    gap: var(--xxxs-spacing);
    // grid-template-columns: repeat(3, max-content);
    height: 52px;
    border-radius: var(--s-radius);
    align-items: flex-end;
    padding: var(--xxs-spacing) var(--xxs-spacing);
    cursor: pointer;
    box-shadow: var(--s-shadow);
    border: 2px solid transparent;
    cursor: pointer;

    .color {
      width: 8px;
      height: 8px;
      border-radius: 20px;
    }

    h4 {
      display: none;
    }
    label{
      display: grid;
      gap: var(--xxxs-spacing);
      padding: 0 !important;
      align-items: flex-start;
      margin: 0;
      position: absolute;
      cursor: pointer;
      bottom: var(--xxs-spacing);
      left: var(--xxs-spacing);
      // position: relative;
    }
    input[type="radio"] {
      /* Hide the default radio button */
      // display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: min-content;
      opacity: 0 !important;
 
    }
    
    input[type="radio"] + div {
      width: 16px;
      height: 16px;
      border: 2px solid var(--grey-08);
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer;
      background-color: white; /* Add the background color for the unselected radio button */
    }
    
    input[type="radio"]:checked + div {
      background-color: var(--primary-color-1000); /* Add the background color for the selected radio button */
    }

    &:hover {
      border: 2px solid var(--grey-08);
      transition: all .3s;
    }
    transition: all .3s;

  }
}
@keyframes slidesFormAnimationSlide {
  0% {
    background-color: var(--grey-08);
  }

  5% {
    background-color: #423737;
  }

  35% {
    background-color: #423737;
  }

  40% {
    background-color: #A77C55;
  }

  65% {
    background-color: #A77C55;
  }

  70% {
    background-color: #A8674B;
  }

  95% {
    background-color: #A8674B;
  }

  100% {
    background-color: #423737;
  }
}

@keyframes slidesFormAnimationContent {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  5% {
    transform: translateX(0);
    opacity: 1;
  }

  35% {
    transform: translateX(0);
    opacity: 1;
  }

  40% {
    transform: translateX(-38%);
    opacity: 1;
  }

  58% {
    transform: translateX(-38%);
    opacity: 1;
  }

  62% {
    transform: translateX(-76%);
    opacity: 1;
  }

  75% {
    transform: translateX(-76%);
    opacity: 1;
  }

  88% {
    transform: translateX(-76%);
    opacity: 1;
  }

  90% {
    transform: translateX(-126%);
    opacity: 0;
  }

  100% {
    transform: scale(1.1) translateX(0);
    opacity: 0;
  }
}

@keyframes classicFormAnimation {
  20% {
    transform: scale(1.1) translateY(0);
    opacity: 1;
  }

  35% {
    transform: scale(1.1) translateY(-12%);
    opacity: 1;
  }

  50% {
    transform: scale(1.1) translateY(-32%);
    opacity: 1;
  }

  60% {
    transform: scale(1.1) translateY(-52%);
    opacity: 1;
  }

  75% {
    transform: scale(1.1) translateY(-68%);
    opacity: 1;
  }

  80% {
    transform: scale(1.1) translateY(-120%);
    opacity: 0;
  }

  100% {
    transform: scale(1.1) translateY(0);
    opacity: 0;
  }
}

.create {
  h1 {
    letter-spacing: -1px;
    color: var(--grey-60);
    margin: var(--xs-spacing) 0;
  }

  p {
    color: var(--grey-30);
  }

  h2 {
    font-size: var(--s-text);
    padding: var(--s-spacing) 0;
    color: var(--grey-30);
    font-weight: normal;
  }

  hr {
    border: 1px solid var(--white-100);
  }

  ul.start_from_scratch {
    display: grid;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    margin-bottom: var(--l-spacing);
    gap: var(--s-spacing);
    align-items: center;
    justify-content: center;
    max-width: 320px;

    @include devices(desktop) {
      grid-template-columns: repeat(2, 288px);
      // justify-content: flex-start;
      max-width: unset;
    }

    .frame {
      height: 148px;
      overflow: hidden;
      border-radius: var(--s-radius);
      background-color: var(--grey-08);
      cursor: pointer;
      position: relative;

      h3 {
        position: absolute;
        font-size: var(--xs-text);
        color: var(--white-100);
        bottom: 0;
        right: 8px;
        padding: var(--xxxs-spacing) var(--xxs-spacing);
        border-radius: var(--s-radius);
        z-index: 1;
        box-shadow: var(--s-shadow);
        opacity: 0;
        transition: all 1s;
        margin-bottom: -32px;
        height: 32px;
      }

      svg {
        margin: auto;
        display: block;
      }

      border: 3px solid var(--white-100);
      box-shadow: var(--s-shadow);
      transition: all 0.5s;

      &:hover {
        border: 3px solid var(--white-100);
        box-shadow: var(--l-shadow);
        transition: all 0.5s;

        h3 {
          opacity: 1;
          transition: all 0.5s;
          margin-bottom: 12px;
        }
      }

      &.classicform {
        h3 {
          background-color: #C28460;
          svg {
            padding: 0;
          }
        }
 

        svg.emptystate__illustration {
          padding: 88px 4px 40px 40px;

          @include devices(desktop) {
            transform: scale(1.2);
          }
        }

        &:hover {
          svg.emptystate__illustration {
            animation: classicFormAnimation 5.5s infinite;
          }
        }
      }

      &.slideshowform {
        display: grid;
        grid-template-columns: 3fr 4fr;

        h3 {
          background-color: #955455;
        }

        .slideImage {
          height: 100%;
          background-color:rgb(66, 55, 55);
          transition: all 1s;
        }

        .content {
          overflow: hidden;

          svg {
            padding: 36px 4px 40px 20px;
          }
        }

        &:hover {
          .slideImage {
            animation: slidesFormAnimationSlide 3.5s infinite;
          }

          .content {
            svg {
              animation: slidesFormAnimationContent 3.5s infinite;
            }
          }
        }
      }
    }
  }
}

.clasicform{
  p{
    font-size: var(--s-text);
  }
}

.modal {
  form {
    position: relative;
    .error{
      color: #e85e61;
      letter-spacing: -.5px;
      font-size: var(--xs-text);
      padding: 8px;
      margin-top: 20px;
      background-color: #c5636514;
      border-radius: 8px;
    }
    .actions {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      gap: var(--s-spacing);
      padding-top: var(--xxxs-spacing);
      margin-right: 0;
      @include devices(desktop){
        position: absolute;
        bottom: 40px;
        right: 0;
      }
      .primary {
        padding: var(--xs-spacing);
        border-radius: var(--s-radius);
        background-color: var(--grey-100);
        color: var(--white-100);
        border: none;
        font-family: 'Archia';
        cursor: pointer;
        font-size: var(--s-text);
        width: max-content;
        transition: all .5s;

        &:disabled{
          background-color: var(--grey-08);
          color: var(--grey-30);
          transition: all .5s;
        }
      }
      .secondary{
        padding: var(--xxs-spacing) var(--xs-spacing);
        border-radius: var(--s-radius);
        background: transparent;
        font-family: 'Archia';
        color: var(--grey-60);
        border: 2px solid transparent;
        transition: all .5s;
        border: 2px solid var(--grey-08);

        &:hover{
          color: var(--grey-100);
          transition: all .5s;
        }
      }
    }
  }
  h2 {
    color: var(--grey-30);
    margin: 0;
    padding: 0;
    padding-bottom: var(--xxs-spacing);
    letter-spacing: -.5px;
    font-weight: normal;
    font-size: var(--xs-text);

    display: grid;
    grid-template-columns: auto max-content;

    span {
      text-transform: none;
      letter-spacing: -0.5px;
    }
  }
  h3{
    font-size: var(--m-text);
    letter-spacing: -1px;
    color:var(--grey-100);
    margin:0;
    margin-top: 0;
    @include devices(desktop){
      font-size: var(--l-text);
    }
  }

  ul {
    margin-bottom: var(--s-spacing);
    display: grid;
    gap:var(--xxxs-spacing);
    li {
        padding: var(--xxs-spacing) var(--xxs-spacing);
        cursor: pointer;
        p{
          padding-bottom: 0 !important;
        }
      &:hover{
        p {
          &:before{
            border: 2px solid var(--grey-100);
            transition: all .3s;
          }
        }
      }
      &.active {
        position: relative;
        border-radius: var(--s-radius);
        border: 2px solid var(--grey-08);

        &:hover {
          // border: 2px solid var(--grey-100);
          transition: all .3s;
        }
        p {
          color: var(--grey-100);
          transition: all .3s;
          &:before{
            border: 2px solid var(--grey-100);
            transition: all .3s;
          }
          &:after{
          content: '';
          width: 12px;
          height: 12px;
          display: block;
          background: var(--grey-100);
          border-radius: 20px;
          position: absolute;
          top:4px;
          left: -24px;
          transition: all .3s;
          }
        }
      }
      p {
        position: relative;
        color: var(--grey-60);
        letter-spacing: -.5px;
        transition: all .3s;

        &:before{
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          border: 2px solid var(--grey-30);
          border-radius: 20px;
          position: absolute;
          top:0;
          left: -28px;
          transition: all .3s;

        }

      }

      
    }
  }

  .create {
    display: grid;
    padding: var(--m-spacing);


  }
}
.close{
  position: absolute;
  right: var(--xs-spacing);
  top: var(--xs-spacing);
  color:var(--grey-30);
  cursor: pointer;
  z-index: 5;
  svg{
    width: 32px !important;
    height: 32px !important;
    position:static;
    animation: none;
  }
}
.inputError{
  border: 2px solid vaR(--red-100) !important;
}
.typeDescription{
  text-align: center;
  h3{
    font-size: var(--xs-text);
    margin: var(--s-spacing) 0 var(--xxs-spacing);
    color:var(--grey-60);
  }
  p{
    font-size: var(--xs-text);
    line-height: var(--m-text);
    color:var(--grey-30);
    font-weight: normal;
  }
}
.emptyStateTitle{
  text-align: center;
  h2{
    font-size: var(--s-text);
    color:var(--grey-60);
    font-weight: bold;
    letter-spacing: -.5px;
    margin: var(--m-spacing) 0 var(--m-spacing);
    padding: 0;
  }
}


button.createFormButton {
  padding: var(--xs-spacing);
  border-radius: 8px;
  background-color: var(--grey-100);
  font-family: 'Archia';
  font-size: var(--s-text);
  letter-spacing: -.5px;
  font-weight: bold;
  color: var(--white-100);
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  margin-bottom: var(--xxs-spacing);
  transition: all .5s;
  border: 5px solid transparent;
  &:hover{
    box-shadow: var(--l-shadow);
    transition: all .3s;
    border-color: var(--white-08);
  }
}
.empty_hub__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */
  color: var(--grey-30);

  .empty_hub{ 
    text-align: center;
    max-width: 400px;
    margin: auto;
    padding-bottom: var(--l-spacing);

    h2 {
      font-weight: bold;
      margin: 0;
      padding: 0;
      font-size: var(--s-text);
      padding-bottom: var(--xxs-spacing);
  color: var(--grey-60);
}
    p{
      font-size: var(--xs-text);
      line-height: 1.55em;
      
    }
  }
}

