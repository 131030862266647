@import 'mixin.scss';

//Generic styles
.block_item_full {
    display: grid;
    grid-template-columns: unset;
    margin-bottom: unset;

    @include devices(desktop) {
        margin-bottom: unset;
        width: 100%;
        align-items: center;
    }
}

.block_edit_inner.block_empty_state {
    width: 100%;
    max-width: var(--l-block);
    display: block;
    text-align: center;
    opacity: .7;
    font-size: var(--xs-text);

    img {
        width: 100%;
        box-shadow: var(--s-shadow);
        border-radius: var(--s-radius);
        border: 2px solid var(--white-100);
        margin-bottom: 16px;

        @include devices(tabletanddesktop) {
            min-width: 200px;
            max-width: 240px;
        }

    }

    h2 {
        font-size: var(--s-text);
        padding-bottom: var(--xxs-spacing);
    }
}



//Block Edit
.block_edit_with_nav {
    .inline_editor_container {
        .duplicate_icon {
            border-left: 1px solid var(--white-36);
            padding-left: var(--xs-spacing);
            border-radius: 0;
        }

        .duplicate_icon,
        .remove_icon {
            cursor: pointer;

            svg {
                font-size: 16px;
            }

            &:hover {
                color: var(--white-100);
            }
        }
    }

    ul {
        // align-items: self-start;
        user-select: none;


        &:hover {
            .block_item_nav {
                li {
                    opacity: .5;
                    transition: all .5s;
                }
            }

            .item_drag_icon {
                opacity: .5;
                transition: all .5s;
            }
        }

        .block_nav_on_drag {
            margin: unset;
        }

        .block_nav_on_drag {
            padding: 0;
            display: block;
        }
    }
}

.block_edit_inner {
    max-width: calc(var(--l-block));
    margin: auto;
    display: grid;
    // padding-top: var(--xxs-spacing);
    padding-top: var(--m-spacing);
    padding-bottom: var(--m-spacing);

    @include devices(mobileandtablet) {
        width: 100%;
        display: block;
    }

    @include devices(desktop) {
        width: fit-content;
        // min-width: 608px;
    }
}

.block_field_error {

    .field__input,
    path,
    .dropzone,
    &.option_item,
    .option_item_slider {
        background-color: var(--red-10) !important;
        color: var(--red-100) !important;
        border-color: var(--red-100) !important;
    }
}

.format_error {
    color: var(--red-100);
    font-weight: bold;
    font-size: var(--xs-text);
    margin-top: 32px;
}

.block_item_content {
    max-width: 608px;
    margin: auto;
    flex: 1;
    width: 100%;

    input,
    textarea {
        width: 100%;
    }

    &:focus-visible {
        border: none;
    }

    @include devices(desktop) {
        max-width: var(--m-block);
        min-width: var(--s-block);
    }


}

//if block_nav_on_drag which is the DND drop inside the block item do this 
.block_item {

    position: relative;
    grid-area: block_item;

    input,
    textarea {
        color: inherit;
    }

    @include devices(tabletanddesktop) {
        max-width: var(--l-block);
        min-width: var(--m-block);
        // padding-bottom: var(--l-spacing);
    }

}


.block_nav_on_drag {
    display: grid;
    cursor: pointer;
    grid-template-columns: var(--m-spacing) auto var(--m-spacing);

    &.block_item {
        max-width: unset;
    }

    .block_edit_with_nav {
        display: block;
        min-height: 64px; //may change over time
    }

    .full_screen_image {
        margin-left: -40px;
        margin-right: -40px;
        padding-left: var(--m-spacing);
        padding-right: var(--m-spacing);
        z-index: 0;
    }

    .item_drag_icon {
        z-index: 1;
    }
}

.end_legend {
    text-align: right;
}



//Navigation style set up for block item
.block_item_nav {

    display: grid;
    display: none !important;
    row-gap: var(--xxxs-spacing);
    display: flex;
    flex-direction: column;
    // grid-area: block_item_nav;
    padding-left: var(--xs-spacing);
    box-sizing: border-box;

    li {
        transition: all .5s;
        width: var(--m-icon);
        height: var(--m-icon);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white-36);
        border-radius: var(--xxs-radius);
        cursor: pointer;

        &:hover {
            background-color: var(--grey-100);

        }

        svg {
            font-size: 18px;
            fill: var(--white-100)
        }
    }



}




.side_sort {
    .block_nav_on_drag {
        margin-bottom: var(--s-spacing);
        padding-bottom: 0;
    }

    @include devices(mobile) {
        .block_item {
            padding: 0;
        }
    }
}


.block_item_control {
    .block_item {
        margin: 0;
        padding-bottom: 0;
    }
}


//Field type
.full_screen_image {
    background-size: cover;
    background-position: center;
}

.block_logo {
    grid-template-columns: unset;
    display: flex;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    max-width: var(--l-block);
    margin: auto;

    @include devices(mobileandtablet) {
        max-width: unset;
        width: auto;
    }
}

.block_caption {
    padding: var(--s-spacing) 0 var(--m-spacing) 0;
}



//Knob on the side make it almost visible when it is not hovered
.block_item_drag {
    position: relative;

    .item_drag_icon {
        opacity: .08;
    }

    &:hover {
        .item_drag_icon {
            opacity: 1;
        }
    }
}

.block_item_nav {
    li {
        opacity: 0.08;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}


//Button
.add_new_field {
    display: flex;
    column-gap: var(--xxs-spacing);
    font-size: var(--xs-text);
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:focus {
        color: var(--orange-100);
    }
}

//Inside block item
.block_item_inner_container {
    @include devices(desktop) {
        display: flex;
        margin: auto;
        column-gap: var(--m-spacing);
        max-width: var(--l-block);
        min-width: 608px;
    }

    // @include devices(mobileandtablet){
    //     overflow: hidden;
    // }
}

.block_item_media_inner {
    @include devices(mobileandtablet) {
        position: relative;
    }
}

.see_content_prompt {
    @include devices(mobileandtablet) {
        position: absolute;
        bottom: 100px;
        text-align: center;
    }
}



.block_item_media {
    // flex: 1;
    // display: flex;

    img,
    video {
        width: 100%;
        object-fit: cover;
        object-position: top;
        height: 100%;
        // max-width: 600px;
    }

}


.block_item_field_header {
    margin-bottom: var(--s-spacing);
    display: grid;
    row-gap: 8px;

}

.block_item_title {
    display: grid;
    font-size: var(--m-text);
    font-weight: 700;
    grid-auto-flow: column;
    grid-template-columns: auto max-content;

    input,
    textarea {
        color: inherit;
        font-weight: inherit;
    }

    @include devices(mobile) {
        // font-size: var(--sm-text);
        label {
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    @include devices(tinymobile) {
          font-size: var(--sm-text);
        
    }
}

.block_item_description {
    font-size: var(--s-text);
    opacity: .7;
    max-height: 36vh;
    overflow: auto;
    line-height: 1.65;

    input {
        color: inherit;
        font-weight: inherit;

    }

    input,
    textarea {
        line-height: 1.65;
    }




}

//footer

.block_footer {
    .inline_editor_container {
        right: 0;
    }

    .submit_btn_prefix {
        right: 0;
        border-radius: var(--xxs-radius);
    }

    input {
        font-family: inherit;
    }
}

.block_footer_container {
    position: relative;
    row-gap: var(--xxs-spacing);
    display: grid;

}

.block_footer_inner_container {
    position: relative;
    display: grid;
    row-gap: var(--xxs-spacing);
    width: 100%;
}

//Not sure how we should style it
.block_footer_classic {
    .block_footer_buttons {

        // .block_footer_button{
        //     justify-content: flex-end;
        //     margin-right: 0;
        // }
        button,
        input {
            width: max-content;
            font-weight: bold;
        }
    }
}

.block_footer_button_group {
    display: grid;
    grid-auto-flow: column;

}

.block_footer_buttons_wrapper {
    width: 100%;
}

.block_footer_caption {
    position: relative;
    text-align: right;

    input {
        color: inherit;
        font-size: var(--xs-text);
    }
}


.block_footer_button {
    position: relative;
    display: flex;
    column-gap: var(--xxs-spacing);
    box-sizing: border-box;
    margin: unset;
    font-size: var(--sm-text);
    font-weight: bold;

    input {
        color: inherit;

        &::placeholder {
            opacity: 0.5;
            color: inherit;
        }
    }

    &>span {
        display: flex;
        align-items: center;
    }
}

.block_submit_button {
    // padding: var(--xs-spacing);
    box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;
    font-weight: 600;
    justify-content: center;

    input {
        // font-size: var(--m-text);
        text-align: center;
        font-weight: 600;
    }
}

.block_footer_caption {
    font-size: var(--xs-text);
    text-align: right;
    font-weight: 500;

    @include devices(mobileandtablet) {
        padding: var(--m-spacing);
        padding-top: 0;
        box-sizing: border-box;
    }

    input {
        font-size: var(--xs-text);
        text-align: right;
        font-weight: 500;
    }
}



//Error
.block_error {
    position: relative;

    @include devices(mobileandtablet) {
        padding: 0 var(--m-spacing);
        box-sizing: border-box;
    }
}

.block_error_inner {
    background-color: var(--red-10);
    padding: var(--xxs-spacing) var(--xs-spacing);
    box-sizing: border-box;
    backdrop-filter: blur(16px);
    border-radius: var(--s-radius);
    color: var(--red-100);
    font-size: var(--xs-text);
}



.block_item_field_content {
    flex: 1;

    &:focus-visible,
    &:focus-within {
        outline: none;
    }
}

//progress
.block_progress {
    width: 100%;
    margin-top: 12px;
    background-color: var(--grey-08);
    height: 4px;
    position: sticky;
    // bottom: 0;
    @include devices(mobile){
        margin-top: unset;
    }
}

.block_progress_inner {
    position: absolute;
    height: 20px;
    transition: all 1s;
}




.field_type_media {
    background-position: center;

    .block_item_title {
        opacity: 0;
        height: 0;
    }

}

.block_item_voucher {
    display: grid;
    grid-template-columns: auto 40px;
    align-items: center;
    margin-top: var(--sm-spacing);
    min-height: 52px;

    input,
    textarea {
        border: none !important;
        background-color: transparent;
        padding: var(--xs-spacing);
        margin: 0;
        font-weight: bold;
        box-sizing: border-box;
        align-items: center;
        vertical-align: middle;
    }

    svg {
        cursor: pointer;
    }
}





//-----------------------------------
//Editor page
//Specially for editor page
//-------------------------
.editor_page_skeleton {
    .block_logo {
        max-width: var(--l-block);
    }

    .field_interaction_status_prompt {
        position: absolute;
        right: var(--xs-spacing);
        top: var(--xs-spacing);
        padding: var(--xxs-spacing);
        box-sizing: border-box;
    }

    .block_empty_state_container {
        min-height: 60vh;
        display: flex;
        align-items: center;
    }


    .block_logo_classic {
        padding-left: 0;
        padding-right: 0;

        &.block_logo_center {
            justify-content: center;
        }
    }


    .rating_selected,
    .rating_unselected {
        fill: unset;

        &:hover {
            // opacity: 0.5 !important;
            // transition: unset !important;
            transform: scale(1);
            fill: inherit;
            color: inherit;

            // path {
            //     opacity: 0.5 !important;
            // }
        }

    }

    .block_classic_skeleton {
        min-height: calc(100vh - 192px);

        .block_item_media {
            @include devices(desktop) {
                max-height: 40vh;
                overflow: hidden;
            }
        }
    }

    .block_footer {
        @include devices(mobileandtablet) {
            padding-bottom: var(--ml-spacing);
        }
    }

    .block_footer_container_slideshow {
        .inline_editor_container {
            display: none;
        }

        &.block_footer_container_last_field {
            .inline_editor_container {
                display: block;

                @include devices(mobileandtablet) {
                    top: -20px;
                    max-width: max-content;
                    right: 20px;
                }
            }
        }
    }
}


//-----------------------------------
//Form page
//Similar but minus all the block_edit_w_nav and block_edit_inner
//-------------------------
.form_page_skeleton {
    @include devices(desktop) {
        min-height: 100vh;
    }


    .block_item {
        margin: auto;
        grid-template-columns: unset;
        display: block;
        // padding-top: var(--xxs-spacing);
        padding-top: var(--m-spacing);
        padding-bottom: var(--m-spacing);
    }

    .block_caption {
        padding: var(--s-spacing) 0 var(--m-spacing) 0;

        .block_edit_inner,
        .block_item,
        .block_item_content {
            padding: 0;
            // padding-bottom: var(--m-spacing);
        }

        .block_item {
            margin: unset;
        }
    }



    input {
        border: 1px solid #000;
    }

    .block_logo_left {
        align-items: flex-start;

        // left: var(--m-spacing);
        @include devices(mobileandtablet) {
            padding-left: var(--s-spacing);
            box-sizing: border-box;
        }
    }

    .block_logo_center {
        justify-content: center;
    }

    @include devices(mobile) {
        .block_footer_slideshow {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }
}



@import 'blockclassic.scss';
@import 'blockclassicmobile.scss';
@import 'blockslideshow.scss';
@import 'blockprint.scss';
@import 'blockstatic.scss';
@import 'blockspecificfield.scss';


.original {
    position: relative;
}


.block_item_slider_option {
    position: relative;
    max-width: unset;

    .block_item_field_header {
        margin-bottom: var(--s-spacing);

        @include devices(tabletanddesktop) {
            max-width: var(--m-block);
            margin-left: auto;
            margin-right: auto;

            // padding-bottom: var(--l-spacing);
        }
    }

    .block_item_content_slideshow_inner {
        @include devices(tabletanddesktop) {
            padding: 0;
        }
    }
}



.dropzone {
    min-height: 172px;
    vertical-align: middle;
    text-align: center;
    padding: var(--s-spacing);
    border-radius: 8px;
    vertical-align: center;
    align-items: center;
    justify-content: center;
    display: grid;

    cursor: pointer;

    h4 {
        font-size: var(--xs-text);
        margin: var(--xs-spacing) 0 0;

        // padding-bottom: var(--xxs-spacing);
        i {
            border: 2px solid;
            padding: var(--xxxs-spacing) var(--xxs-spacing);
            border-radius: var(--s-radius);
            opacity: .5;
            transition: all .25s;

            &:hover {
                opacity: 1;
                transition: all .25s;
            }
        }
    }

    img {
        max-height: 220px;
        overflow: hidden;
        border-radius: var(--s-radius);
    }

    p {
        opacity: 1;
        font-size: var(--xs-text);

        span {
            display: block;
            font-weight: bold;
            margin-top: var(--xxxs-spacing);
            font-weight: bold;

            svg {
                font-size: var(--s-text);
            }

            cursor: pointer;
            opacity: 1;
            transition: all .3s;

            &:hover {
                opacity: 1;
                transition: all .3s;
            }
        }
    }

    svg {
        opacity: 1;
        font-size: 32px;
    }
}

.remove__file_upload {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: var(--xs-text);
    font-weight: bold;
    cursor: pointer;
    opacity: 0.3;
    transition: all .25s;
    padding: var(--xs-spacing) 0;

    svg {
        font-size: var(--s-text);
    }

    &:hover {
        opacity: 1;
        transition: all .25s;
    }
}

.response_summary {
    position: fixed;
    bottom: 28px;
    right: 32px;
    user-select: none;
    padding: var(--xxs-spacing) var(--xxs-spacing);
    border-radius: 20px;
    border: 2px solid transparent;
    transition: all .2s;

    &:hover {
        // background-color: var(--grey-03);
        border: 2px solid var(--grey-08);
        transition: all .2s;
    }
}

.block_item_legal {
    border-radius: var(--xs-radius);
    height: 132px;
    overflow: auto;

    textarea {
        padding: var(--xs-spacing);
        height: 100%;
        font-size: 14px;
    }
}

//Background
.smartpath_open {
    background: linear-gradient(144deg, #F3E5E7 18.18%, #E1C8DC 109.8%);
    /* Set the border-image-source to a linear gradient */

}
//Border overwrite without important
.smartpath_open{
    .smartpath_open{
        border-image-source: linear-gradient(144deg, #F3E5E7 18.18%, #E1C8DC 109.8%);
        border-image-width: 20;
        border-image-slice: 1;
        border-image-outset: 0;
        border-image-repeat: round;
        border-style: solid;
    }
}


.field_w_pathID{
    position: relative;
}

.field_w_o_pathID{
    position: relative;

}

.smartpath_open{
    .field_w_o_pathID{
        opacity: 0.1;
        display: none;
    }

}