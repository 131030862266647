@import '../mixin.scss';

.page{
    position: relative;
    background-color: var(--bg-color);
    
}

.container{
    display: grid;
    // grid-template-columns: 4fr 8fr;
    @include devices(desktop){
        display: flex;
        height: calc(100vh - 92px);
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
}
.subheader{
    display: grid;
    grid-template-columns: auto max-content;
    padding: 0 var(--sm-spacing) var(--s-spacing);
    @include devices(desktop){
        padding:0;
        grid-template-columns: unset;
    }
    h2, select {
        font-weight: bold;
        font-size: var(--s-text);
        line-height: 32px;
        letter-spacing: -.5px;
        color: var(--grey-100);
    }
    h2{
        color: var(--grey-60);
    }
}
.form_select{
    flex-basis: 38%; 
    flex: 1;
    padding:var(--xs-spacing) 0 var(--m-spacing);
    width: 100vw;
    @include devices(desktop){
        padding:var(--m-spacing) var(--m-spacing);
        overflow-y: auto;
        width: unset;
    }
    h1 {
        font-weight: 800;
        font-size: var(--l-text);
        line-height: 32px;
        letter-spacing: -1px;
        padding: 0 var(--sm-spacing) var(--s-spacing);
    @include devices(desktop){
        padding-left: 0;
        padding: 0 var(--sm-spacing) var(--s-spacing) 0;
    }
    
    }
    .form_list{
        display:flex;
        width: 100%;
        overflow-x: auto;
        padding-left:var(--s-spacing);
        box-sizing: border-box;
        @include devices(desktop){
            padding: var(--xs-spacing) 0 var(--s-spacing) 0;
            row-gap: var(--xxs-spacing);
            display: grid;
            overflow-x: hidden;
            justify-content: unset;
            width: unset;
        }
        li.form_list_item {
            &.active{
                border:2px solid var(--grey-100);
                .title{
                    color: var(--grey-100);
                }
            }
            background: var(--bg-color--20);
            // padding:0 !important 32px;
            padding:var(--xxs-spacing);
            // padding-bottom: 0 !important;
            // padding-top: 0 !important;
            display: block;
            cursor: pointer;
            min-width: 132px;
            display: grid;
            margin: 0 var(--xxs-spacing);
            border-radius: var(--s-radius);
            @include devices(desktop){
                margin: 0;
                min-width: unset;
                padding: var(--xs-spacing);
            }
            .title{
                font-size: var(--s-text);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--grey-60);
                font-weight: bold;
            }
            
            .meta_data{
                font-size: var(--xs-text);
                color: var(--grey-30);
                display: grid;
                grid-template-columns: auto max-content;
                align-items: center;
                letter-spacing: -.5px;
                padding-top: var(--s-spacing);
                .record_count{
                    color: var(--primary-color-60);
                }
            }
            .form_stats{
                display: grid;
                padding-top: var(--xxs-spacing);
                grid-template-columns: max-content max-content auto;
                label {
                    display: grid;
                    grid-template-columns: max-content max-content;
                    svg{
                        padding-left:8px;
                        height: 20px;
                    }
                }
            }


            &:hover{
                background-color: var(--grey-100);
                transition: var(--speedy-transition);
                .title {
                    color: var(--white-100);
                }
                .meta_data {
                    color: var(--white-36);
                }
            }
        }
        }
        .load_more{
            width: 100%;
            display: block;
            border:none;
            font-family: 'Archia';
            background: transparent;
            font-weight: bold;
            padding: var(--xs-spacing) var(--xs-spacing) 0;
            color:var(--primary-color-1000);
            width: max-content;
            cursor: pointer;
            @include devices(desktop){
                padding: 0 var(--xxs-spacing);
            }
        }
        p{
            font-weight: normal;
            color:var(--grey-30);
            letter-spacing: -.5px;
            font-size: var(--xs-text);
        }
        .form_list__empty{
            color: var(--grey-30);
            padding-right:var(--s-spacing);
            h2{
                color: var(--grey-60);
                font-size: var(--s-text);
                line-height: 1.5;
                font-weight: normal;
                padding-bottom: var(--xxs-spacing);
            }
            p {
                color:var(--grey-30);
                padding-bottom: var(--xs-spacing);
                line-height: 1.5em;
            }
            svg{
                width: 88px;
                height: 88px;
                @include devices(desktop){
                    width: 128px;
                    height: 128px;
                }
            }
        }
}
@keyframes fadeUpHubMobile {
    0%   {opacity:0; }
    100%   {opacity:1;}
}
.hub{  
    flex-basis: 62%;
    background-color: var(--white-100);
    padding: var(--m-spacing);
    overflow-y: auto;
    border-top-left-radius: 40px;
    top: 102px;
    // z-index: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    animation-name: fadeUpHubMobile;
    animation-duration: .6s;
    
    @include devices(mobile){
        min-height: 100vh;
    }

    @include devices(desktop){
        z-index: 0;
        padding:var(--m-spacing) var(--l-spacing);
        animation: unset;
        animation-duration: unset;
        align-items: flex-start;
        display: grid;
        // position: sticky;
    }
    .hub_content{
        display: grid;
        row-gap: var(--xs-spacing);
        h1 {
            font-size: var(--m-text);
            letter-spacing: -1px;
            margin-bottom: var(--xxxs-spacing);
        }
        h2{
            font-size: var(--s-text);
            margin-top: var(--xs-spacing);
        }
    }
    .form_info{
        .form_created_date{
            font-size: var(--xs-text);
            color: var(--grey-60);
        }
    }
    .form_info__meta{
        display: grid;
        grid-template-columns: auto max-content;
    }
    .form_preview{
        display: grid;
        // grid-template-rows: 2fr 2fr;
        grid-template-columns: 1fr;
        row-gap: var(--s-spacing);
        @include devices(desktop){
            grid-template-columns: 12fr 6fr;
            gap: var(--m-spacing);
            align-items: center;
        }
    }
    .form_preview_frame {
        background: var(--grey-03);
        border: 1px solid var(--grey-08);
        height: 320px;
        border-radius: var(--s-radius);
        position: relative;
        overflow: hidden;
        box-shadow: var(--l-shadow);
        iframe{
            user-select: none;
            overflow: hidden;
        }
        .form_preview_frame_linker{
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
            left: 0;
        }
    }
    // .form_style_info__colors{
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     column-gap: var(--s-spacing);
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     div {
    //         height: 72px;
    //         border:2px solid var(--grey-08);
    //         box-shadow: var(--s-shadow);
    //         border-radius: var(--s-radius);
    //     }
    // }
    .form_style_info__colors__dashboard{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: var(--xxxs-spacing);
        position: absolute;
        bottom: var(--xs-spacing);
        left: var(--xs-spacing);

        div {
            border:2px solid var(--grey-08);
            box-shadow: var(--s-shadow);
            border-radius: 20px;
            width: 32px;
            height: 32px;
            border: 2px solid var(--white-100);
            box-shadow: var(--s-shadow);
        }
    }
    .form_style_info__style{
        display: grid;
        padding-top: var(--s-spacing);
        li {
            width: 100%;
            display: grid;
            grid-template-columns: 2fr auto;
            color:var(--grey-100);
            font-size: var(--s-text);
            padding: var(--xxs-spacing) 0 var(--xs-spacing);
            border-bottom: 2px solid var(--grey-08);
            &:last-child{
                border-bottom: none;

            }
            text-transform: capitalize;


        }
        .style_label{
            color:var(--grey-60);
        }
    }
    .form_action{
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: var(--xxs-spacing);
        align-content: flex-end;
        .cta {
            font-size: var(--xs-text);
            padding: var(--xs-spacing) var(--xxs-spacing);
            margin: var(--xxs-spacing) 0;
            font-weight: bold;
            border-radius: var(--s-radius);
            color: var(--grey-100);
            border: 2px solid;
            text-align: center;
            display: block;
            cursor: pointer;
            &.primary{
                background-color: var(--grey-100);
                color: var(--white-100);
            }
        }
    }
    .form_style_info__colors_font{
        position: relative;
        display: grid;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        h1{
            // z-index: 1;
            margin:0;
            font-size: 20px;
            line-height: 28px;
            opacity: .5;
        }

    }
    .form_result{
        display: grid;
        box-shadow: var(--s-shadow);
        background-color: var(--white-100);
        width: 100%;
        border: 2px solid var(--grey-08);
        border-radius: var(--s-radius);
        @include devices(desktop){
            grid-template-columns: max-content auto;
            gap: var(--xs-spacing);
        }
        column-gap: var(--s-spacing);
        li {
            padding: var(--xs-spacing) var(--xs-spacing);
            text-align: center;
            @include devices(desktop){
                padding: var(--xs-spacing) var(--s-spacing);

            }
            h1 {
                margin:0;
                font-size: var(--m-text);
                font-weight: normal;
                letter-spacing: -2px;
                text-align: left;
                display: flex;
                align-items: center;
                @include devices(desktop){
                    font-size: var(--l-text);
                    svg {
                    padding-top: var(--xxs-spacing);
                    }
                }
                svg{
                    font-size: var(--m-text);
                    padding-left: var(--xxs-spacing);
                }
            }
            label{
                color: var(--grey-100);
                font-size: var(--xs-text);
                display: flex;
                color:var(--grey-60);
                align-items: flex-end;
                justify-content: left;
                column-gap: var(--xxxs-spacing);
                text-align: left;
                width: 100%;
                grid-template-columns: max-content max-content;
                text-transform: capitalize;
                justify-content: space-between; /* Or any other alignment you prefer */
                svg {
                    width: 20px;
                }
                @include devices(desktop){
                    width: max-content;
                }
            }
            p {
                color: var(--grey-60);
                font-size: var(--xs-text);
                line-height: 1.3em;
                padding: var(--xxs-spacing) var(--s-spacing);
            }
            &.form_result_block__right{
                display: flex;
                justify-content: flex-start;
                border-top: 2px solid var(--grey-08);
                @include devices(desktop){
                    border-top: none;

                }
                div{
                    padding-right: var(--s-spacing);
                    @include devices(desktop){
                        padding-right: 0;
                    }
                }
                label{
                    column-gap: 0;
                }
                @include devices(desktop){
                    text-align: right;
                    justify-content: flex-end;
                    h1{
                        text-align: right;
                    }
                    div{
                        padding-left: var(--s-spacing);
                    }
                }
            }
        }
    }
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: grid;
    grid-template-columns: auto max-content;
    font-size: var(--xs-text);
    color:var(--grey-60);
    align-items: center;
  }
  
  /* Dropdown select */
  .dropdown select {
    display: block;

    // padding: var(--xxxs-spacing) var(--xxs-spacing);
    padding-right: var(--sm-spacing);
    border: 0;
    border-radius: var(--s-radius);
    appearance: none;
    // border: 2px solid var(--grey-08);
    cursor: pointer;
    font-size: var(--xs-text);
    color:var(--grey-60);
    position: relative; /* Added for arrow positioning */
  }
  
  /* Dropdown select hover state */
  .dropdown select:hover {
    transition: all .5s ease-in-out;
  }

  
  /* Dropdown select disabled state */
  .dropdown select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Dropdown arrow */
  .dropdown_arrow svg{
    position: absolute;
    color: var(--primary-color-1000);

    top: 24%;
    right: 0; /* Adjust the positioning as needed */
    width: 20px;
    height: 20px;
  }
  
  /* Show arrow on hover */
  .dropdown select:hover:after {
    transform: translateY(-50%) rotate(-180deg);
  }
  .showingSearchResult{
    display: flex;
    align-items: center;
    gap:var(--xxs-spacing);
    background-color: var(--primary-color-20);
    text-transform: none;
    letter-spacing: -.5px;
    justify-content: flex-end;
    font-size: var(--s-text);
    padding: var(--xxxs-spacing) var(--xs-spacing);
    width: max-content;
    border-radius: var(--s-radius);
    color:var(--primary-color-1000);
    font-weight:bold;
    cursor: pointer;
    transition: all .5s;
    @include devices(desktop){
        margin: auto;
        margin-right: 0;
    }
    &:hover{
        background-color: var(--primary-color-1000);
        color:var(--white-100);
        transition: all .5s;
    }
  }
  .dashboardreport_switcher{
    text-transform: uppercase;
    font-size: var(--xs-text);
    letter-spacing: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    @include devices(desktop){
        grid-template-columns: min-content min-content auto 1fr;
        width: 100%;
        display: grid;
        gap: var(--xxs-spacing);
        justify-content: flex-start;
        align-items:center;
    }
    a{  
        padding: var(--xxs-spacing) var(--xs-spacing) var(--xs-spacing);
        box-sizing: border-box;
        color:var(--grey-60);
        flex-basis: 50%;
        display: flex;
        border-bottom: 2px solid var(--grey-08);
        margin: var(--xs-spacing) 0;
        @include devices(desktop){
            align-items: center;
            justify-content: left;
            width: max-content;
            margin: 0;
            grid-template-columns: max-content max-content;
            display: grid;
            flex-basis: unset;
            border-bottom: none;
            padding:0 var(--xs-spacing) 0 0;
        }
        cursor: pointer;
        svg{
            font-size: 20px;
            padding-right: var(--xxs-spacing);
        }
        &.active{
            color:var(--grey-100);
            font-weight: bold;
            // background-color: var(--grey-100);
            border-radius: 12px 12px 0 0;
            padding-bottom: 10px;
            border-bottom: 6px solid var(--grey-100);
            @include devices(desktop){
                background-color:transparent;
                border-bottom: none;
                padding-bottom: 0;
                color:var(--grey-60);
                border-radius: unset;
                border-bottom: none;
                svg{
                    color:var(--grey-100);
                }
            }


        }
    }
  }
  .form_CSV_download{
    font-weight: bold;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    align-items: flex-end;
    span{
        padding-right: var(--xxxs-spacing);
        @include devices(mobile){
            display: none;
        }
    }
    svg {
        font-size: 16px;
    }
    @include devices(desktop){
        padding-left: var(--s-spacing);
        font-size: unset;
    }
    svg{
        padding-right: var(--xxxs-spacing);
    }

    &:hover {
        color:var(--primary-color-1000);
        transition: all .3s;
    }
  }
.clickable{
    cursor: pointer;
}