@import '../mixin.scss';


.logo-stack__asset {
    width: 100%;
    height: 100%;
    max-height: 40px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--xs-spacing);
    box-sizing: border-box;
    overflow: auto;

    img {

        max-height: 40px;
    }
    &.monochrome{
        filter: grayscale(100%);
    }

}

.logo-stack__container {
    display: flex;
    // overflow: auto;
}

.marquee-wrapper {
    width: var(--m-block);
    display: flex;
    overflow: hidden;
}

.marquee-container {
    display: flex;
    animation: marquee 10s linear infinite reverse;
}

.logostack-container{
    display: flex;
    overflow: auto;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

//Editor
.page__editor .logostack {
    .carousel.carousel-slider {
        overflow: visible;
        height: 50px;
        padding-bottom: 0;
        margin-top: var(--sm-spacing);
    }

    .carousel-slider{
        @include devices(mobile){
            padding-bottom: var(--m-spacing);
            overflow: visible;
            width: 100%;
        }
    }

    .logo-stack__container{
        @include devices(mobile){
            width: 100%;;
        }
    }

    .logo_stack__item{
        @include devices(mobile){
            flex: 1;
        }
    }


    // .slider-wrapper{
    //    height: 50px;
    //    overflow-x: hidden;
    // }
    .logo-stack__container {
        display: block;
    }

    .control-dots {
        bottom: 0;
    }

    .option_item_wrapper{
        align-items: center;
    }

    .slide {
        margin-top: unset;
        transform: scale(1);

        &:hover,
        &.focus {
            transform: scale(1) !important;
            .drag_indicator{
                opacity: 1;
            }
        }

        .drag_indicator{
            font-size: 16px;
            opacity: .3;
        }
        &.selected{
            .drag_indicator{
                visibility: visible;
            }
        }

    }

    .logo-stack__asset {
        padding: 0;
    }


}


.logo-stack__asset-editor {
    display: grid;
    grid-template-columns: auto min-content;
    width: 100%;
    display: grid;
    align-items: center;
    column-gap: var(--xxs-spacing);
}