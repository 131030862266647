@import 'mixin.scss';


.form_page_skeleton{
    &.print_page_skeleton{

        min-height: 100vh;
        display: grid;
        grid-auto-rows: min-content;
        background-color: var(--white-100);
        

        .block_item{
            padding-bottom: var(--s-spacing);
            padding-top: var(--s-spacing);
            // page-break-before: auto;
            @media print{
                max-width: 80vw;
                margin: auto;
                padding-top: .25in;
            }
        }
        .print_text{
            margin: 0;
            padding: var(--xs-spacing);
            box-sizing: border-box;
            display: block;
            width: 100%;

            // border-radius: 28px;
        }

        .field__input{
            padding: var(--xs-spacing);
        }

        .list_of_options{
            display: grid;
            grid-auto-flow: row;
            row-gap: var(--xxs-spacing);;
        }
  
        .option_item{
            display: grid;
            grid-template-columns: 30px auto;
            align-items: center;
            padding: var(--xs-spacing);
            box-sizing: border-box;
  
        }
        .block_item_content{
            max-height: 150px;
            // overflow: hidden;

        }

        .block_item_field_header{
            padding-bottom: 0;

        }
        .print_input_img{
            width: 100%;
            height: 100px;
            background-size: contain;
            background-repeat: no-repeat;

        }

        .block_print_header{
            margin-bottom: 10px;
            font-weight: 600;
            display: block;
        }
        .block_logo{
            width: unset;

            max-width: var(--m-block);
            margin: auto;
        }

    }
}

.print_version_modal{
    position: relative;
    max-height: 80vh;
    overflow: auto;
    border-radius: var(--s-radius);
    .actions{
        display: grid;
        width:100%;
        grid-template-columns:8fr 72px 72px;
        box-sizing: border-box;
        gap: var(--xxxs-spacing);
        align-items: center;
        background-color: var(--white-100);
        padding: 0 var(--xs-spacing);
        border-bottom: 2px solid var(--grey-03);
    // margin-bottom: 20px;
        // position: absolute;
        // top:0;
        // left: 0;
        // backdrop-filter: blur(36px);
        h1{
            font-size: var(--xs-text);
            text-transform: uppercase;
            letter-spacing: 1px;
            color: var(--grey-100);
            user-select: none;
            margin:0;
        }
        svg{
            font-size: 18px;
        }
        button{
            border: 2px solid var(--grey-08);
            color: var(--grey-100);
            background: transparent;
            display: flex;
            justify-content: center;
            gap:2px;
            font-weight: bold;
            user-select: none;  
            cursor: pointer;
            align-items: center;
            padding: var(--xxs-spacing) var(--xxs-spacing);
            border-radius: var(--xs-radius);
            height: max-content;
            font-family: 'Archia';
            font-weight: bold;
            font-size: var(--xs-text);
            transition: all .5s;
            &:hover{
                border: 2px solid var(--grey-100);
                transition: all .5s;
            }
        }
    }
}

.print_scale_bg{
    -webkit-print-color-adjust: exact; 
    display: flex !important;
    width: 100%;
    padding: var(--xs-spacing);
    box-sizing: border-box;
}

.print_rating{
    span{
        &:hover, &:focus{
            opacity: unset;
        }
    }
    svg{
        background-color: unset !important;
    }
}

.page_content {
    @media print{
    margin: 0.25in; /* Margin around the content */
    padding: 0.25in; /* Padding within the content */
    }
  }
  
  /* Apply additional styles for page break */
  .page_content + .page_content {
    page-break-before: always; /* Add a page break before each content block */
  }

  .page_break{
    page-break-before: always; 
    @media print{
        padding-top: .25in;
    }
  }

  .page_watermark{
    visibility: hidden;
    @media print{
        position: fixed;
        width: 100%;
        bottom: 8px;
        left: 8px;
        visibility: visible;
    }
  }

  