@import '../mixin.scss';

.container {
    // background-color: var(--grey-100);

    position: absolute;
    top: -50px;
    padding: var(--xxs-spacing) var(--xs-spacing);
    box-sizing: border-box;
    border-radius: var(--xs-radius);
    font-size: var(--xs-text);
    z-index: 2;
    font-family: 'Archia';
    

    // @include devices(mobileandtablet){
    //     left: -30px;
    // }

    @include devices(tabletanddesktop) {
        background-color: var(--grey-100);
    }

    @include devices(mobile) {
        padding: 0;
        border-radius: 0;
        list-style-type: none;
        display: flex;
        // overflow-y: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent line breaks */
        
        width: 100%;

        ul {
            background-color: var(--grey-100);
            border-radius: var(--xs-radius);
            // overflow: auto;

            &:after {
                content: ' ';
            }

            // border-radius: var(--xs-radius);
        }

        li:not(:first-child) {
            // background: rgb(255 255 255 / 25%);
            padding: 4px;
            box-sizing: border-box;
            display: flex;
            border-radius: 4px;
            svg {
                fill: var(--white-36);
                width: 20px;
                height: 20px;

            }
        }


        li {
            padding: var(--xxs-spacing) var(--xs-spacing);
            box-sizing: border-box;
        }

        &.is_open_edit {
            overflow: unset;
        }
    }

    ul {
        display: flex;
        align-items: center;
        column-gap: var(--xs-spacing);
        color: var(--white-60);
        li{
            cursor: pointer;
        }
        @include devices(mobile) {
            column-gap: var(--xxxs-spacing);
        }
    }
}

.inline_sub_section_item_mobile{
    display: flex;
    align-items: center;
    column-gap: var(--xxs-spacing);
    & > div{
        display: flex;
        align-items: center;
        select{ color: var(--white-100)}
    }
}

.inline_sub_section_item_mobile_dropdown{
    position: relative;
    padding-right: 1px;
    box-sizing: border-box;
    svg{
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
    }
}

.inline_is_allow_overflow{
    overflow: auto;
    // ul{
    //     overflow: unset;
    // }
    @include devices(tablet){
        max-width: 400px;
        overflow: auto;
        padding-right: 0;
    }
}

.inline_not_overflow{
    overflow: unset;

    
}

.add_logo {
    top: 85px;
}

.header {
    font-weight: 700;
    color: var(--white-100);
    display: flex;
    

    span {
        display: flex;
        align-items: center;
        white-space: pre;

        // @include devices(mobile) {
        //     max-width: 30px;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        // }

    }
}

.manage_field {
    position: relative;

    li {
        svg {
            font-size: var(--m-text);
            vertical-align: middle;
        }
    }

    &:before {
        @include devices(tabletanddesktop) {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            background-color: rgba(255, 255, 255, .16);
            left: -8px;
        }
    }
}


.selected {
    font-weight: 600;
    color: var(--white-100);

    @include devices(mobile) {
        svg {
            fill: var(--white-100) !important;
        }
    }
}

.edit_fields {
    position: absolute;
    padding: var(--xxs-spacing) var(--xs-spacing);
    box-sizing: border-box;
    // top: 45px;
    left: 0;
    width: 300px;
    background-color: var(--grey-100);
    border-radius: 0 0 var(--xs-radius) var(--xs-radius);
    font-size: var(--xs-text);
    margin-top: 4px;
    max-height: 280px;
    overflow: auto;

    @include devices(tabletanddesktop) {
        width: 100%;
    }

    @include devices(mobile) {
        top: 40px;
    }

    header {
        font-weight: 700;
        color: var(--white-100);
        margin-bottom: var(--xxs-spacing);

    }
}

.edit_field_inner_container {
    ul {
        display: block;
        display: grid;
        row-gap: var(--xxxs-spacing);
        margin-bottom: var(--xs-spacing);
    }
}

.update_field_search {
    background-color: var(--white-100);
    padding-left: var(--xxs-spacing);
    box-sizing: border-box;
    border-radius: var(--xs-radius);
    display: flex;
    column-gap: var(--xxxs-spacing);
    margin-bottom: var(--xxs-spacing);
    align-items: center;
}

.inline_sub_section {
    position: relative;
    cursor: pointer;
    user-select: none;
    header{
        white-space: pre;
    }
}

.inline_sub_section_item {
    position: absolute;
    top: 49px;
    padding: var(--xxs-spacing) var(--xs-spacing);
    width: 150px;
    box-sizing: border-box;
    background-color: var(--grey-100);
    border-radius: 0 0 8px 8px;

    &>header {
        color: var(--white-100);
        font-weight: 600;
        margin-bottom: var(--xxxs-spacing);
      

    }

    select {
        color: inherit;
    }

}

.inline_sub_section_item_choice {
    display: flex;

    header {
        flex-grow: 1;
        color: var(--white-100);
        

    }
}

.inline_sub_section_logo{
    top: 24px;
}


/* InlineEditor.module.scss */

/* Styling for the custom radio button */
.custom_radio {
    position: relative;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: grid;
    background-color: hsla(0, 0%, 100%, .2);

    input {
        animation: unset;
    }

}

.submit_btn_prefixes {
    width: unset;


    .inline_sub_section_item_choice {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: var(--xxs-spacing);
    }
}

.custom_radio input[type=radio] {
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.radio_fake_input {
    width: 100%;
    height: 100%;
    display: grid;
    border-radius: 3px;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 0;

}


.custom_radio input[type=radio]:checked+div {
    background-color: #9f7b53;
}


//field specific
.logo_filter {
    right: 0;
    top: 29px;
    width: auto;

    .inline_sub_section_item_choice {

        column-gap: var(--xxs-spacing);
    }
}

.image_position{
    top: 28px;

}

.li_sticky{
    position: sticky;
    right: 0;
    background-color: var(--grey-100);
    svg{
        fill: var(--white-100) !important;
    }
}

.li_sticky_right{
    right: 28px;
}