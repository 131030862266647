@import '../mixin.scss';

@keyframes sideImageAnimation_accessibility{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes labelAnimation_accessibility {
  0% { 
      opacity:0; 
  }
  10% { 
      opacity:0; 
  }
  100% { 
      opacity:1; 
  }
}

@keyframes inputAnimation_accessibility {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  10% {

    opacity: 0;
    transform: scale(.9);
  }
  45% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.formstyle__accessibility{
//errror
  .block_error {
    div {
      border-radius: 32px;
      animation: accessibilityError 0.25s ease-in-out; 
    }
  }
  @keyframes accessibilityError {
    0%{
      margin-right: -20px;
    }
    25% {
      margin-right: 0;
    }
    100% {
      margin-right: 0;
    }
  }
  // fields styles
  .item__voucher{
    border-bottom: 2px solid;
    border-radius: 2px;
    background-color: transparent !important;
    textarea, input {
      padding-left: 0;
    }
  }
  .item__legal{
    border: 2px solid;
    background-color: transparent !important;
  }
  .option_item{
    padding: var(--xxs-spacing);
    border: 2px solid;
    margin-bottom: var(--xxs-spacing);
    border-radius: 8px;
    transition: all .2s;
    opacity: .5;
    background-color: transparent !important;
    &:hover, &:focus{
      transition: all .2s;
      opacity: 1;
      background-color: var(--white-100) !important;
      color: var(--grey-100) !important;
      border-color: transparent;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    }
  }
  .option_item_selected{
    opacity: 1;
  }
  .field__type_scale_frame{
    gap: 0;
    border-radius: 32px;
    overflow: hidden;
  }
  .field__type_scale_empty{
    opacity: 1;
    border: 3px solid transparent;
    transition: all .2s;
    // border-radius: 32px;
      border-radius: 0;
      opacity: .5;
    // background-color: transparent !important;
    &:hover{
      transition: all .2s;
      opacity: 1;
    }
  }
  .field__type_scale_selected{
    transition: all .2s;
      border: none;
      border-radius: 0;
      position: relative;
      i{
        display: flex;
        position: absolute;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;
        top:0;
        left: 0;

      }
      span{
        display: none !important;
      }
      &:first-of-type{
        border-radius: 32px 0 0 32px;
      }
  }
  .field__type_rating{
    svg{
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding:4px;
      background-color: transparent !important;
      transition: all .25s;
      &:hover{
        transition: all .25s;
      }
    }
    .star-empty{
      transition: all .5s;
      box-shadow:  0 0 0;
      transition: all .25s;
      border:none;
      box-shadow:  4px 4px 0 transparent;
      opacity: .5;
    &:hover{
        transition: all .15s;
        opacity: 1;
      }
    }
  }
  .field__type_media{
    border-radius: 12px;
  }
  .field__type_pdf{
    border-radius: 12px;
    max-width: max-content;
    border:none;
    box-shadow: none;
    transition: all .5s;
    &:hover{
      border: 2px solid;
    }
  }
  .field__input__number{
    .field__input__number__controls{
      border-radius: 8px;
      div{
        display: flex;
        padding:4px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        border: 2px solid rgba(0, 0, 0, 0.1) !important;
        border-bottom: 4px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0 4px 4px 0;
        transition: all .5s;

        &:hover, &:focus{
          opacity: 1;
          outline: 2px solid inherit;
          transition: all .5s;
          border-color: inherit !important;

        }
        &:first-of-type{
        border-radius: 4px 0 0 4px;
        border-right: 0 !important;
        margin-right: -2px;
        &:hover, &:focus{
          border-right: 2px solid !important;
        }
        }
      }
    }
  }
  // button styles
  button.previous{
      background: transparent !important;
      width: min-content;
      transition: all .2s;
      padding: 16px 16px;
      opacity: 1;
      font-weight: bold;
      border-radius: 8px;
      border:2px solid;
      cursor: pointer;

      &:hover{
        transition: all .2s;
        transform: scale(1);
        opacity: 1;
        box-shadow: none;
      }
    }
    .button--submit{

      border-color: rgba(0, 0, 0, 0.1) !important;

    }
    .button--submit, .button--next{
      transition: all .2s;
      // box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      appearance: button;
      border: solid transparent;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 0;
      outline: none;
      overflow: visible;
      padding: 16px;
      text-align: center;
      touch-action: manipulation;
      transition: all .1s;
      user-select: none;
      -webkit-user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.07) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.01) 0px -36px 30px 0px inset;
      &:hover{
        transition: all .1s;
        filter: brightness(1.1);
        border: solid transparent;
        border-width: 0 0 0;
        -webkit-filter: brightness(1.1);
        box-shadow: rgba(0, 0, 0, 0.07) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.07) 0px 16px 8px, rgba(0, 0, 0, 0.03) 0px 32px 16px;
      }
    }


    // media on the left

        .container__item--content--animation-title{
        }

        &.formtype__classic{
          .container__item--media div{
            max-height: 380px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
          }
        }
        &.formtype__slideshow{
          .container__item--media {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            @include devices(desktop){
              height: 100vh;
            }
            div{
              width: 100%;
            }
            img,video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              overflow: hidden;
            }
          }
        }
        .signature__area{
          border: 2px solid;
          border-radius: 0 !important; 
        }
        .signature-canvas{
          opacity: .5;
          transition: all .5s;

          &:hover, &:focus{
            transition: all .5s;
            opacity: 1;
          }
        }
        //fileuploader
        .dropzone{
          border: 2px dashed;
          opacity: .5;
          background: transparent !important;
          &:hover, &:focus{
            transition: all .5s;
            border: 2px solid;
            opacity: 1;
          }
        }
        // datepicker
        .interactive_block{
          .react-datepicker-wrapper {
            width: 100%;
            position: relative;
            svg {
              position: absolute;
              top:8px;
              right:0;
            }
          }

          .react-datepicker__day{
            border-bottom: 3px solid;
            width: max-content !important;
            margin: 4px auto;
            min-width: 32px !important;
          }
          .react-datepicker__day:hover{
            border-radius: 8px;
          }
          .field__input,.react-datepicker-wrapper input {
                padding: 16px 12px;
                display: flex;
                border:none;
                border-bottom: 2px solid;
                // margin-bottom: 32px;
                transition: .75s all;
                background-color: transparent !important;
                opacity: .5;
                    &:focus{
                        opacity: 1;
                        border-radius: 8px;
                        transition: .75s all;
                        border:none;
                        background-color: var(--white-100) !important;
                        color: var(--grey-100) !important;
                        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;                    }
                    &::placeholder{
                      opacity: .75;
                      color: inherit;
                    }
                }
        }
    }



.container__item--media--loader{
  width: 100%;
  background-color: inherit;
}

