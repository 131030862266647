@import '../mixin.scss';
.pagination{
    align-items: right;  
    width: max-content;
    margin:auto 0 auto auto;
    padding-bottom: var(--m-spacing);
    padding-top: var(--xs-spacing);
    button {
        background: transparent;
        border: none;
        font-family: 'Archia';
        font-size: var(--xs-text);
        cursor: pointer;
        border-radius: var(--xs-radius);
        color: var(--grey-60);
        letter-spacing: -.5px;
        padding: var(--xxxs-spacing);
        min-width:40px;
        height: 40px;
        @include devices(desktop){
            min-width: 32px;
            height: 32px;
        }
        &:hover{
            background-color: var(--grey-08);
            color: var(--grey-100);
            transition: all .5s ease-in-out;
        }
        &.active {
            background-color: var(--grey-100);
            color:var(--white-100);
            &:hover{
                background-color: var(--primary-color-1000);
                transition: all .5s ease-in-out;
            }
        }
    }
}