@import 'mixin.scss';

.block_logo_slideshow {
    position: fixed;
    z-index: 1;
    top: var(--m-spacing);
    // left: var(--m-spacing);
    padding: 0;

    &.block_logo_center {
        margin: auto;
        left: 0;
        right: 0;
    }

}



.block_slideshow_skeleton {
    min-height: calc(100vh - 280px);
    display: grid;
    align-items: center;
    border-radius: var(--s-radius);

    // @include devices(tabletanddesktop){
    //     min-height: 100vh;
    // }
    @include devices(mobileandtablet) {
        grid-template-rows: unset;
    }

    @include devices (tabletanddesktop) {
        min-height: calc(100vh - 40px);
    }

    .block_item_media_inner {
        min-height: 100vh;

        img,
        video {
            min-height: 100vh;
        }
    }

    .logo_position_left {
        left: 40px;
    }

    .block_item {
        max-width: unset;

        @include devices(mobile) {
            // min-height: 100vh;
            display: grid;
            align-items: center;
            padding: 0 var(--s-spacing);
            box-sizing: border-box;

        }
    }

    //Special for slideshow on form only
    .slideshow_no_img {
        @include devices(mobileandtablet) {
            min-height: 100vh;
            align-items: center;
            // padding-top: 150px;
            padding: unset;
        }

    }

    .slideshow_w_img {
        @include devices(mobileandtablet) {
            // height: 100vh;
            align-items: center;
        }


    }

    .block_item_content {
        grid-template-rows: auto min-content;

        @include devices(mobileandtablet) {
            padding: var(--m-spacing);
            // min-height: 85vh;
            box-sizing: border-box;
        }
    }


    .block_edit_inner {
        // grid-template-columns: auto var(--l-spacing);
        grid-template-areas: 'block_item';

        @include devices(desktop) {
            // max-width: unset;
            width: 100%;
            padding: 0;

            &>img,
            &>video {
                max-height: unset;
            }
        }

        @include devices(mobileandtablet) {
            display: block;
            padding-top: 0;
            // grid-template-areas: 'block_item_nav block_item';
            // display: grid;
            // grid-template-columns: max-content;
            // padding-top: 0;

        }

        &.block_caption {
            padding: var(--s-spacing) 0;
            box-sizing: border-box;
        }
    }

    .block_buttons {
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;

        button {
            @include devices(tabletanddesktop) {
                max-width: 50%;
            }
        }
    }

    .block_item_inner_container {
        max-width: unset;
    }

    .block_logo_inline {
        top: 40px;
    }

    .inline_editor_container {
        @include devices(mobileandtablet) {
            left: 0;
            top: 0;
        }
    }

    .block_item_media {
        max-height: calc(100vh - 40px);
    }

    .block_item_nav {
        @include devices(desktop) {
            margin: auto;
        }
    }

    .block_item_content_slideshow_inner {
        @include devices(tabletanddesktop) {
            padding-left: var(--m-spacing);
            padding-right: var(--m-spacing);
            box-sizing: border-box;
        }

        @include devices(mobile) {
            overflow: hidden;
        }
    }


    .block_item_content {
        position: relative;
        // @include devices(mobileandtablet){
        //     padding: 0 var(--m-spacing);
        // }

        @include devices(desktop) {
            margin: auto;
        }
    }

}


//-----------------------------------
//Editor page
//Specially for editor page
//-------------------------
.editor_page_skeleton {
    .block_logo_slideshow {
        position: absolute;
        left: var(--m-spacing);

        &.block_logo_center {
            justify-content: center;
            left: 0;
        }
    }

    .block_item_media {
        @include devices(tabletanddesktop) {
            max-width: 50%;
        }
    }

    .block_item_inner_container {
        @include devices(tabletanddesktop) {
            padding-right: var(--m-spacing);
            box-sizing: border-box;
        }

    }

    .block_footer_slideshow {
        @include devices(tabletanddesktop) {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding-bottom: var(--s-spacing);
        }

        
        
        .block_submit_button {
            justify-content: center;
        }

        .block_footer_button_group {
            display: grid;

            @include devices(tabletanddesktop) {
                grid-template-columns: auto var(--submit-btn-width);
            }

            @include devices(mobile) {
                grid-template-columns: 40px auto;
                grid-template-columns: min-content auto;
                align-items: center;
                column-gap: var(--xs-spacing);
                padding: var(--s-spacing);
                box-sizing: border-box;
            }
        }

        .block_footer_caption {
            input {
                text-align: right;
                font-size: var(--xs-text);
                padding: 0;
            }
        }

        .inline_editor_container {
            right: 0;
            left: unset;
        }
    }

    .block_slideshow_skeleton {
        .block_item_field {
            display: flex;
            align-items: center;
        }

        .block_edit_with_nav {
            .block_edit_inner {
                @include devices(mobileandtablet) {
                    // min-height: 100vh;
                    display: grid;
                    align-items: center;
                    grid-template-columns: unset;
                    grid-template-areas: 'block_item';
                }
            }
        }


        .block_item_inner_container {
            @include devices(tabletanddesktop) {
                max-width: unset;
                min-height: calc(100vh - 40px);
            }

            @include devices(tablet) {
                display: flex;
            }

        }

        .block_item {
            width: auto;
        }

        .block_edit_inner {
            @include devices(desktoponly) {

                max-width: unset;
            }
        }


    }


}

.is_not_desktop {
    .slideshow_w_img {
        @include devices(mobile) {
            padding: 0;
        }

        .block_item_field_content {
            max-width: unset;
        }

        .block_item_content {
            box-sizing: border-box;

            @include devices(mobile) {
                // padding: 0 var(--s-spacing);
                // min-height: 80vh;
                display: grid;
                grid-template-rows: min-content min-content;
                align-content: center;

            }

            @include devices(tabletanddesktop) {
                padding-right: var(--s-spacing);
            }
        }
    }

    .block_logo_slideshow {
        max-width: unset;
        width: fit-content;
    }


    .block_edit_inner {
        @include devices(tabletanddesktop) {
            grid-template-columns: unset;
            grid-template-areas: unset;
        }
    }

    .block_item_field {
        @include devices(tabletanddesktop) {
            column-gap: var(--xs-spacing);
        }
    }


    .block_slideshow_skeleton {
        .slideshow_w_img {
            .block_item_content {
                // min-height: 80vh;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include devices(mobile) {
                    min-height: 50vh;
                    padding: 0 var(--m-spacing);
                }
            }
        }
    }




}



//-----------------------------------
//Form page
//Similar but minus all the block_edit_w_nav and block_edit_inner
//-------------------------

.form_page_skeleton {




    .block_logo_slideshow {
        box-sizing: border-box;

        @include devices(mobileandtablet) {
            padding: 0 var(--s-spacing);
        }

        &.block_logo_left {
            max-width: var(--l-block);
            margin: auto;
            left: 0;
            right: 0;

        }
    }

    .block_slideshow_skeleton {
        display: block;

        .block_item {
            padding: 0;
            width: unset;
        }


        //Slideshow height on mobile
        .block_item_inner_container,
        .slideshow_no_img {
            @include devices(mobile) {
                min-height: 85vh;
            }

        }

        .block_footer_buttons {
            @include devices(mobile) {
                padding: 0 var(--xxs-spacing);
                box-sizing: border-box;
            }
        }

        .block_item_inner_container,
        .block_item_media {
            min-height: 100vh;
            overflow: hidden;

        }

        .slideshow_w_img {
            @include devices(mobileandtablet) {
                min-height: 100vh;
            }
        }

        .block_footer_button_group {

            @include devices(tabletanddesktop) {
                grid-template-columns: auto var(--submit-btn-width);
            }

            @include devices(mobile) {


                grid-template-columns: min-content auto;
                align-items: center;
                column-gap: var(--xs-spacing);

            }
        }

        .block_footer_caption {
            @include devices(mobileandtablet) {
                padding: 0 var(--s-spacing);
            }
        }

        .block_item_media {

            img,
            video {
                max-width: unset;
            }

            @include devices(desktop) {
                //percentage the show media only on desktop
                max-width: 50vw;
            }
        }

        .block_error {
            @include devices(tabletanddesktop) {
                display: flex;
                justify-content: flex-end;
            }
        }

        .block_error,
        .block_footer_buttons {
            @include devices(tabletanddesktop) {
                padding-left: var(--m-spacing);
                padding-right: var(--m-spacing);
                box-sizing: border-box;
            }
        }

        .block_submit_button {
            white-space: pre;
            overflow: hidden;
        }

        .block_item_content {
            @include devices(mobileandtablet) {

                display: grid;
                // align-items: flex-end;
            }
        }

        .block_footer_container {
            @include devices(tabletanddesktop) {
                width: 100%;
                position: fixed;
                left: 0;
                bottom: 0;
                padding: 0;

            }
            // @include devices(mobile){
            //     background: inherit;
            //     &:before{
            //         content: '';
            //         background: inherit;
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         right: 0;
            //         bottom: 0;
            //         box-shadow: inset 0 0 100px rgba(255, 255, 255, .80);
            //         filter: blur(10px);
            //     }
            // }
        }

    }

    &.form_page_skeleton_slideshow {
        @include devices(mobileandtablet) {
            .block_w_img {

                .block_item_media,
                .block_item_media_inner {
                    min-height: unset;
                    height: 45vh;

                    img,
                    video {
                        min-height: unset;
                        height: 45vh;
                    }
                }
                .block_item_content {
                    min-height: unset;
                }
            }


        }
    }

}


@import 'blockslideshowthankyou.scss';