@import '../mixin.scss';
$dark-mode-color: var(--white-36);
$light-mode-color: var(--grey-30);

.dark_mode {
  .block_nav {
    li {
      background: $dark-mode-color;
    }
  }

  .block_nav_item {
    .item_drag_icon {
      svg {
        path {
          fill: $dark-mode-color
        }
      }
    }
  }

  .logo-placeholder svg path {

    fill: $dark-mode-color;

  }

  .add_new_field {
    color: $dark-mode-color
  }

}

.light_mode {
  .block_nav {
    li {
      background: $light-mode-color;
    }
  }

  .block_nav_item {
    .item_drag_icon {
      svg {
        fill: $light-mode-color;

      }
    }
  }

  .logo-placeholder svg path {
    fill: $light-mode-color;
  }

  .add_new_field {
    color: $light-mode-color
  }

}



// date picker form
@keyframes slideIn {
  0% {
    margin-top: 40px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}


.react-datepicker__current-month {
  padding: 8px 0 12px;
}

.field__input__date {
  position: relative;

  svg {
    position: absolute;
    top: 16px;
    right: 12px;
  }
}

.react-datepicker-popper {
  position: relative !important;
  transform: none !important;
  transition: all .5s;
  animation: slideIn .5s;
  /* Add the slideIn animation here */
}

.react-datepicker__day--selected {
  border-radius: 8px !important;
}

.react-datepicker__day--outside-month {
  opacity: .6;
}


.react-datepicker__header {
  padding: 0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  width: 100%;
  border: 0 !important;
  border-radius: 8px !important;
}

.react-datepicker__header {
  background: transparent !important;
  border: none !important;
}

.react-datepicker__month-container {
  width: 100%;
  box-sizing: border-box;
  font-family: Archia;
  font-size: 16px;
  border-radius: 8px;
  backdrop-filter: blur(16px);

}

.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center !important;
  align-items: center !important;
}

.react-datepicker__day {
  width: 100% !important;
  padding: var(--xxxs-spacing) var(--xxxs-spacing);
  line-height: 1.5em !important;
  box-sizing: border-box;
}

.react-datepicker__day-name {
  padding: 0;
  width: 100% !important;
  line-height: 1.5em !important;
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0.5;

}

.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__month-container {
  padding: 8px;
  box-sizing: border-box;
}

.react-datepicker__navigation {
  top: 18px !important;
}

//signature canvas
.signature-canvas {
  width: 100%;
  height: 200px;
  border-radius: 12px;
}

//Field specific
.video,
.pdf {
  .wrapper__block {
    padding-bottom: 0;
  }
}

#twitch-embed {
  // width: 100% !important;

}

.item__desc,
.item__title {
  white-space: pre-line;
  line-height: 1.65;
}


.loader__full-page {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: grid;
  gap: 20px;
  text-align: center;
  color: var(--grey-30);
}

.page__editor {
  .signature__area {
    z-index: unset;
  }
}

// color restyling
.chrome-picker {
  box-sizing: border-box !important;
  // padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;

  div {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: auto;

    .flexbox-fix {
      div {
        svg {
          margin: -10px 0 0 !important;

          &:hover {
            fill: var(--grey-100) !important;
          }
        }
      }

      .flexbox-fix {
        width: 100%;
        box-sizing: border-box;

        label {
          margin-top: var(--xxs-spacing) !important;
        }

        input {
          margin-bottom: 0 !important;
          box-sizing: border-box;
          font-family: 'Archia';
          color: var(--grey-60);
          font-weight: bold;
          border-radius: var(--xs-radius) !important;
          height: 32px !important;

          &:focus {
            border: 2px solid var(--grey-100) !important;
          }
        }


      }
    }
  }
}



//RC Slider
.page-background-slider {
  .rc-slider {
    height: 20px;
  }

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    height: 8px;
  }

  .rc-slider-rail {
    background-color: var(--grey-08);
  }

  .rc-slider-track {
    background-color: var(--grey-100);
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--grey-100);
    box-shadow: 0 0 0 5px var(--grey-30);
  }


  .rc-slider-handle {
    margin-top: -3px;
    border-color: var(--grey-100);

    &:hover {
      border-color: var(--grey-100);
    }

    &:focus-visible {
      box-shadow: 0 0 0 3px var(--grey-100);
    }
  }
}

.field__input__number__controls {
  height: auto;
}

.tel {
  .interactive_block {
    svg {
      @include devices(mobile) {
        right: 23px;
      }
    }
  }
}

.formtype__slideshow {

  .smartpath,
  .multichoice {
    .interactive_block {
      min-height: 200px;
    }
  }
}

.smartpath_open{
  .smartpath{
    display: none;
  }
}


// //trying out to create media query ish
// @media (max-width: 768px) {
//   /* CSS rules for screens with a maximum width of 768px */
//   .browser {
//     width: 100%;
//     background-color: lightblue;
//   }
// }

// @media (min-width: 769px) {
//   /* CSS rules for screens with a minimum width of 769px */
//   .browser {
//     width: 80%;
//     background-color: lightgray;
//   }
// }