//Fonts
@font-face {
  font-family: "Archia";   
  src: local("Archia"),
    url("./font/archia-medium-webfont.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Archia";   
  src: local("Archia"),
    url("./font/archia-semibold-webfont.woff") format("woff");
  font-weight: 700;
}

//Reset 
body {
  margin: 0;
  font-family:'Archia', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

ol,ul,li,h1,h2,p{
    list-style: none;
    padding: 0;
    margin: 0;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  &:focus{
    outline: none;
  }
}

option {
  color: initial;
  background-color: initial;
  font-weight: initial;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
textarea {
  /* Remove default border */
  border: none;
  border-radius: 0;
  /* Remove default padding */
  padding: 0;
  /* Remove default margin */
  margin: 0;
  /* Remove default background */
  background-color: transparent;
  /* Remove default outline */
  outline: none;
  /* Set font properties */
  font-family: inherit;
  font-size: inherit;
  /* Set box-sizing to prevent width/height calculation issues */
  box-sizing: border-box;
  /* Set other desired styles */
  /* ... */
}

button,
a,
input,
textarea,
select {
  outline: none; /* Remove the default focus outline */
  &:focus-visible{
    outline: none;
}
}

input, textarea {
  text-size-adjust: 100%; /* Disable automatic text size adjustment */
}



.container__block{
/* Customize the scrollbar track (background) */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set your custom color for the thumb */
}
}


body {
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

/* Optional: Style the input field */
input[type="text"] {
  width: 100%;
  // padding: 10px;
  box-sizing: border-box;
}

/* Scroll to top when input is focused */
input[type="text"]:focus {
  scroll-margin-top: 0; /* Ensure scroll goes to the top */
  scroll-snap-margin-top: 0; /* Ensure scroll goes to the top */
  scroll-margin-block-start: 0; /* Ensure scroll goes to the top */
  scroll-margin-inline-start: 0; /* Ensure scroll goes to the top */
}

.twitter-picker{
  // background-color: unset !important;
  // border: none !important;
  // box-shadow: none !important;
  // input{
  //   display: none;
  // }
  div:nth-child(3){
    display: grid;
    grid-template-columns: repeat(6,auto);
    padding: 0 !important;
    span +  div{
     display: none !important;
    }
  }
}



//Imports

@import '../src/css/editor/_global.scss';
@import '../src/css/fields/_slideroption.scss';
@import '../src/css/fields/_logostack.scss';
@import '../src/css/fields/_text.scss';





@import '../src/css/pagestyles/_line.scss';
@import '../src/css/pagestyles/_round.scss';
@import '../src/css/pagestyles/_prism.scss';
@import '../src/css/pagestyles/_classic.scss';
@import '../src/css/pagestyles/_accessibility.scss';
:root{
 

  --navy-100: rgba(36, 33, 38,1);
  --navy-70: rgba(36, 33, 38,.7);

  --haze-1000: rgba(101, 124, 144,1);

  --bg-color: rgba(241, 235, 230,1);
  --bg-color-10: rgba(241, 235, 230,.5);
  --bg-color--60: #AC9684;
  --bg-color--20: #E5DFDA;

  --orange-1000: rgba(194, 132, 96,1);
  --orange-100: rgba(194, 132, 96,1);
  --orange-30: rgba(194, 132, 96,.3);
  
  --green-100: rgb(67, 111, 83);  
  --red-100: rgb(226, 68, 47);
  --red-10: rgba(226, 68, 47, 0.1);


  --grey-03: rgba(33, 30, 34, 0.03);
  --grey-08: rgba(33, 30, 34, 0.08);
  --grey-30: rgba(33, 30, 34,.38);
  --grey-60: rgba(33, 30, 34,.6);
  --grey-65:rgba(33, 30, 34, 0.65);
  --grey-100: rgba(33, 30, 34,1);
  --grey-90: rgba(33, 30, 34,0.95);

  --primary-color-1000: rgba(224, 144, 108, 1);
  --primary-color-20: rgba(224, 144, 108, .2);

  --white-100: rgba(255, 255, 255,1);
  --white-60: rgba(255, 255, 255,.60);
  --white-50: rgba(255, 255, 255,.50);
  --white-36: rgba(255, 255, 255,.36);
  --white-08: rgba(255, 255, 255,.08);

  //spacing
  --xl-spacing: 100px;
  --l-spacing: 80px;
  --ml-spacing: 48px;
  --m-spacing: 40px;
  --sm-spacing: 28px;
  --s-spacing: 20px;
  --xs-spacing: 16px;
  --xxs-spacing: 8px;
  --xxxs-spacing: 4px;

  --s-nav-spacing: 12px;

  //shadow
  --s-shadow: 0px 4px 4px 0px rgba(33, 30, 34, 0.05);
  --m-shadow:  0px 3px 7px 0px rgba(0, 0, 0, 0.13);
  --l-shadow: rgba(50, 50, 93, 0.125) 0px 13px 27px -5px, rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;;



  //radius 
  --s-radius: 12px;
  --xs-radius: 8px;
  --xxs-radius: 4px;

  //font-size
  --xxl-text: 48px;
  --xl-text: 40px;
  --l-text: 28px;
  --m-text: 20px;
  --sm-text: 18px;
  --s-text: 16px;
  --xs-text: 14px;
  --gilroy: 'Gilroy';

  //transition
  --speedy-transition: all .3s ease-in-out;
  
  //Container on Editor and Form
  --l-block: 1024px;
  --m-block: 608px;
  --s-block: 400px;
  --submit-btn-width: 240px;
  --option-slider-max-height: 300px;
  --option-slider-img-max-height: 200px;
  --modal-height: 520px;

  //icons
  --m-icon: 26px;
  
}

