.select_group_field{
    color: var(--haze-1000);
    font-weight: 700;
    display: flex;
    padding-top: var(--s-nav-spacing);
    box-sizing: border-box;


    li{
        width: 40px;
        align-items: center;
        display: flex;
        cursor: pointer;
    }
}

.search_block_types{
    margin-bottom: var(--s-nav-spacing);
    position: relative;
    grid-template-columns: 20px auto;
    display: grid;
    align-items: center;
    background-color: var(--grey-08);
    padding: var(--s-nav-spacing);
    box-sizing: border-box;
    border-radius: 8px;

    input[type="text"]{
        all:unset;
        width: 100%;
        border: none;
        font-size: 14px;
        font-family: 'Archia';
        box-sizing: border-box;
        border-radius: 8px;
        background-color: transparent;
    }
    svg{
        font-size: 16px;
        fill: var(--grey-65);
        margin: auto;
    }
}

.list_of_items{

    header{
        display: flex;
        align-items: center;
        grid-gap: 8px;
        margin: var(--s-nav-spacing) 0;
        h1{
            font-size: 14px;
            font-weight: 700;
            color: var(--grey-30);
            
        }
    }
}

.add_field{
    // font-weight: 700;
    font-size: var(--s-text);
    letter-spacing: -.5px;
    color: var(--navy-70);
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
}

.add_field_icon{
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  

}

// .input{
//     // background-color: rgba(83, 36, 142,.08);
//     svg path {
//         fill: rgba(83, 36, 142,1);;
//     }
// }
// .option{
//     // background-color: #f7d9d7;
//     svg path{
//         fill: #ff7870 
//     }
// }
// .scale{
//     // background-color: #cee0e3;
//     svg path{
//         fill: #2199aa 
//     }
// }
// .date{
//     // background-color: #DECA95;
//     svg path{
//         fill: #c78356;
//     }
// }
// .textblock{
//     // background-color: #d2a88e;
//     svg path{
//         fill: #7f5f4d;
//     }
// }
// .media{
//     // background-color: #b6d599;
//     svg path{
//         fill: #529447;
//     }
// }

