@import '../mixin.scss';

  //hack for desktop cause both of them need to have independent height
  @include devices(desktop){
    .scrollable{
        position: fixed;
        width: 300px;
        overflow: auto;
        height: 100%;
    }
    .editor_edit_form_section{
        margin-left: 283px;
    }
  }
  @include devices(mobileandtablet){
    .container{
        display: grid;
    }
  }

.container{
    background-color: var(--bg-color);
    position: relative;
}

.form_container{
    // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
    position: relative;
    // padding-bottom: 80px;


}

.editor_edit_form_section{
    background-color: var(--bg-color);
    border: 20px solid var(--bg-color);
    box-sizing: border-box;
    position: sticky;
    flex-basis: 100%;
    min-height: 100vh;

    @include devices(tabletanddesktop){
      overflow-y: auto;
    }
    
    @include devices(safari){
      overflow: unset;
    }
    
   & > main{
        border-radius: var(--s-radius);
        box-shadow: var(--m-shadow);
        position: relative;
        overflow: hidden;
        box-shadow: rgba(0,0,0,.08) 1px 1px 5px 0px inset, rgba(0,0,0,.075) -1px -1px 5px 1px inset;
    }
}

.form_classic{
    padding-bottom: 80px;
}


//Video
.video_background {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    left: 0;
    overflow: hidden;
    @include devices(desktop){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        box-sizing: border-box;
    }
    
  }
  .image_background{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .video_background video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }



