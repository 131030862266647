@import 'mixin.scss';

//-----------------------------------
//Slideshow on thank you section on both editor and form
//-------------------------

.thankyou_pg_on_slideshow {
    position: relative;
    
    @include devices(mobileandtablet){
        min-height: 100vh;
    }

    .block_logo_slideshow {
        position: relative;
        top: 0;
        left: 0;
        padding-top: var(--m-spacing);
        padding-bottom: var(--m-spacing);
        box-sizing: border-box;

        margin: auto;
    }

    .block_item_content{
        @include devices(mobile){
            padding: var(--s-spacing);
            box-sizing: border-box;
        }
    }

    @include devices(desktop) {
        .block_item_media {
            grid-area: block-media;
        }

        .block_item_content {
            grid-area: block-content;
        }

        .block_right_field_img {
            grid-template-areas: 'block-content block-media';
            display: grid;

            .block_item_media {
                @include devices(tabletanddesktop) {
                    max-width: unset;
                }
            }
        }


        .block_left_field_img {
            grid-template-areas: 'block-media block-content';
            display: grid;
            .block_item_media {
                @include devices(tabletanddesktop) {
                    max-width: unset;
                }
            }
        }
    }


    .block_slideshow_skeleton {
        min-height: 100vh;
        display: block;
        @include devices(mobile){
            min-height: 75vh;
        }

   

        .block_item_field_header {
            margin-bottom: 0;
        }

        .slideshow_no_img {
            height: unset;
        }

        .block_item_inner_container {
            min-height: unset;
        }

        .block_item {
            max-width: var(--m-block);
            flex: 1;
            padding: var(--m-spacing) 0;
            box-sizing: border-box;
            @include devices(mobile){
                padding: 0;
            }
        }

        .full_screen_image {
            max-width: unset;
        }

        .block_item_nav {
            margin: auto
        }
        .slideshow_no_img {
            @include devices(mobile) {
                min-height: unset;
            }
    
        }

    }
    



    .block_item.slideshow_w_img {
        max-width: var(--l-block);

        .block_item_media {
            max-height: 40vh;
            min-height: unset;
            height: unset;
        }
    }
    
    .slideshow_no_img{
        @include devices(mobileandtablet){
            min-height: unset;
        }
    }

}

.form_page_skeleton {
    .thankyou_pg_on_slideshow {
        .block_logo_slideshow {
            padding: var(--m-spacing) 0;
            box-sizing: border-box;
        }

        .block_edit_inner {
            padding: var(--m-spacing) 0;
            box-sizing: border-box;
        }

        .block_item_content {
            padding: 0 var(--s-spacing);
            box-sizing: border-box;
        }
    }
}

.editor_page_skeleton {
    &.thankyou_pg_on_slideshow {
        .block_item {
            padding: 0;
        }

        .logo_placeholder {
            min-width: max-content;
        }

        .block_edit_with_nav {
            padding: var(--m-spacing) 0;
            box-sizing: border-box;
        }

        .block_edit_inner {
            display: grid;
            max-width: var(--l-block);
            grid-template-columns: min-content max-content;
            column-gap: var(--xxxs-spacing);
            width: fit-content;

        }
        
 
        
    }

}