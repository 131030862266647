@import 'mixin.scss';
.container{
    z-index: 0;
    position: absolute;
    bottom: 8px;
    height: 20px;
    left: 8px;
    padding: 8px 12px;
    font-size: 12px;
    margin: 16px;
    border-radius: 30px;
    background: rgba(0,0,0,0.2);
    color:var(--white-100);
    font-family: Gilroy;
    opacity: .90;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
    cursor: pointer;
    user-select: none;
    font-family: inherit;
    display: grid;
    align-items: center;
    font-weight: bold;
    a{
        color: inherit;
    }

    &:hover, &:focus{
        opacity: 1;
        -webkit-transition: all .3s ease-in;
        -o-transition: all .3s ease-in;
        transition: all .3s ease-in;
    }
    
    @include devices(mobile){
        display: none;
    }
}