.dropzone{
    // height: calc(100% - 40px);
    display: flex;
    border: 2px solid var(--white-08);
    cursor: pointer;
    border-radius: var(--s-radius);
}

.main_container{
    display: grid;
    grid-template-rows: auto min-content;
    height: calc(100% - 40px);
}
.instruction{
    font-weight: normal;
    margin-top: var(--xxs-spacing);
    text-transform: capitalize;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    display: flex;
    max-height: 500px;
    overflow: auto;
    border-radius: var(--xs-radius);
    img{
        width: 100%;
        object-fit: scale-down;
    }

}

.image{
    background-color: var(--grey-08);
    padding: var(--xxs-spacing);
    display: flex;
    align-items: center;
    border-radius: var(--xs-radius);
    margin-bottom: 12px;
}

.title{
    font-size: var(--s-text);
    font-weight: 600;
    font-weight: 600;
    
}

.error{
    width: fit-content;
    margin: auto;
    background-color: var(--red-10);

    padding: var(--xxs-spacing);
    box-sizing: border-box;
    border-radius: var(--xs-radius);
}

.pdf{
    // display: flex;
    // column-gap: 8px;
}