@import '../mixin.scss';

//this is in block_item level

.herotext{
    .item__title{
        font-size: var(--xxl-text);
        margin-bottom: var(--xs-spacing);
        line-height: 1.1;
        @include devices(mobile){
            font-size: var(--l-text);

        }
    }
    
    .item__desc{
        @include devices(mobile){
            font-size: var(--xs-text);
        }
    }
}

.textblock, .herotext{
    .container__item--content{
        header{
            margin-bottom: 0;
        }
    }
}