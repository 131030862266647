@import 'mixin.scss';
.block_classic_mobile_skeleton {
    padding-left: var(--s-spacing);
    padding-right: var(--s-spacing);
    box-sizing: border-box;
    min-height: 100%;

    .block_edit_inner {
        grid-template-columns: unset;
        position: relative;

    }

    .block_logo {
        padding: var(--m-spacing);
        box-sizing: border-box;

    }

    // .block_item {
    //     @include devices(mobile){
    //         padding: 0;
    //     }


    // }
    .field_type_media{
        .block_item_content{
            @include devices(desktop){
                min-height: 300px;
            }
        }
    }

    .block_caption{
        padding-top: 0;
    }

    .block_footer{
        padding-top: 150px;
    }




    .block_item_nav {
        position: absolute;
        z-index: 1;
        top: 60px;
        right: 0;
        padding-right: var(--xxs-spacing);
    }


    
    .block_item_media {
       padding-bottom: var(--m-spacing);
        box-sizing: border-box;
    }

    .block_logo_inline {
        left: 0;
    }

    .inline_editor_container {
        max-width: 100%;
        width: fit-content;
    }

    .block_item_inner_container {

        @include devices(mobile) {
            display: grid;
            row-gap: var(--s-spacing);
            // min-height: 180px;
        }
    }
    


}


