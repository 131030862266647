@import '../mixin.scss';
.container{
    position: relative;
    padding-bottom: var(--xs-spacing);
    // overflow: hidden;
    li{
        position: relative;
        padding-top: var(--xxs-spacing);
        padding-left: var(--xxs-spacing);
        &:nth-child(1){
            padding-left: 0;
        }
        &:nth-child(2){
            padding-top: var(--xxs-spacing) ;
        }
        &.add_new_question{
            cursor: pointer;
            user-select: none;
            font-weight: bold;
            font-size: var(--xs-text);
            display: flex;
            align-items: center;
            column-gap: var(--xxxs-spacing);
            padding: var(--xs-spacing) 0;
            color: var(--orange-30);

            &:hover, &:focus{
                color: var(--orange-100);
            }
        }
    }
}

.header{
    display: grid;
    grid-template-columns: auto 24px;
    align-items: center;
    font-size: var(--s-text);
    color: var(--grey-60);
    cursor: pointer;
    
    span{
        font-weight: 600;
        color: var(--grey-60)
    }
    svg path{
        fill: var(--grey-60);
    }
}

.header_active{

    span{
        color: var(--grey-100);
    }
    svg path{
        fill: var(--grey-100);
    }
}

.item_navigator{
   cursor: pointer;
   padding: var(--xxs-spacing);
   box-sizing: border-box;
   user-select: none;
   color: var(--grey-60);
   display: grid;
   grid-auto-flow: column;
   column-gap: var( --xxs-spacing);
   svg{
    height: 20px;
    width: 20px;
   }
   span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: -.5px;

    align-items: center;
    font-size: var(--s-text);
    @include devices(tabletanddesktop){
        display: flex;
    }
   }
}


.item_field{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--xs-radius);
    .item_drag_icon{
        opacity: 0;
        width: 0;
        transition: all .25s;
        
        @include devices(mobile){
            display: none;
        }
        @include devices(tablet){
            display: none;
        }
    }
    &:hover, &:focus{
        .item_drag_icon{
            opacity: .20;
            width: 20px;
            transition: all .25s;
        }
    }
}




.item_drag_active{
    background-color: var(--white-100);
    .item_drag_icon{
        visibility: visible;
    }
}

.dragging_item{
    box-shadow: var( --m-shadow);

}