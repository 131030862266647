@import '../mixin.scss';

@keyframes sideImageAnimation_original{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes labelAnimation_original{
  0% { 
      opacity:0; 
  }
  10% { 
      opacity:0; 
  }
  100% { 
      opacity:1; 
  }
}

@keyframes inputAnimation_original{
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  10% {

    opacity: 0;
    transform: scale(.9);
  }
  35% {
    opacity: 1;
    transform: scale(1.05);
  }
  45% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.formstyle__original{
  //error
  .block_error {
    div {
      border-radius: 8px;
      animation: originalError 0.25s ease-in-out; 
    }
  }
  @keyframes originalError {
    0%{
      transform: scale(.9) rotate(5deg);
    }
    20% {
      transform: scale(1.2) rotate(0);
    }
    25% {
        transform: scale(1) rotate(0);
      }
    100% {
      transform: scale(1) rotate(0);
    }
  }
  // fields styles
  .item__voucher{
    // border-bottom: 2px solid;
    border-radius: 8px;
    border: 2px solid transparent;

    // background-color: transparent !important;
    &:hover{
      border: 2px solid;
    }
    textarea, input {
      // padding-left: 0;
    }
  }
  .item__legal{
    border: none !important;
  }
  .option_item{
    padding: var(--xxs-spacing);
    border: 2px solid;
    margin-bottom: var(--xxs-spacing);
    border-radius: 8px;
    transition: all .2s;
    backdrop-filter: blur(16px);

    border: none !important;
    opacity: .8;
    
    &:hover, &:focus{
      transition: all .2s;
      opacity: 1;
    }
  }
  .option_item_selected{
    opacity: 1;
  }
  .field__type_scale_frame{
    gap: 4px;
    height: 52px;
    border-radius: 12px;
    overflow: hidden;
    backdrop-filter: blur(16px);
  }
  .field__type_scale_empty{
      opacity: 1;
      border: 3px solid transparent;
      transition: all .2s;
      border-radius: 0;
      opacity: .5;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 52px;
      box-sizing: border-box;
    &:hover{
      transition: all .2s;
      opacity: 1;
    }
    @include devices(mobile){
      padding: 4px;
      box-sizing: border-box;
    }
  }
  .field__type_scale_selected{
    transition: all .2s;
      border: none;
      border-radius: 0;
      position: relative;
      i{
        display: flex;
        position: absolute;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 52px;
        top:0;
        left: 0;

      }
      span{
        display: none !important;
      }
      &:first-of-type{
        border-radius: 32px 0 0 32px;
      }
  }
  .field__type_rating{
    gap: 4px;
    border-radius: 12px;
    overflow: hidden;
    .star-filled{
      position: relative;
      i{
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 52px;
        top:0;
        left: 0;
        width: 100%;
      }
    }

    svg{
      width: 100%;
      height: 52px;
      box-sizing: border-box;
      padding:12px;
      transition: all .25s;
      &:hover{
        transition: all .25s;
      }
    }
    .star-empty{
      transition: all .5s;
      box-shadow:  0 0 0;
      transition: all .25s;
      border:none;
      box-shadow:  4px 4px 0 transparent;
      opacity: .5;
    &:hover{
        transition: all .15s;
        opacity: 1;
      }
    }
  }
  .field__type_media{
    border-radius: 12px;
  }
  .field__type_pdf{
    border-radius: 12px;
    max-width: max-content;
    border:none;
    box-shadow: none;
    transition: all .5s;
    &:hover{
      border: 2px solid;
    }
  }
  .field__input__number{
    .field__input__number__controls{
      border-radius: 8px;
      div{
        display: flex;
        padding:4px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        border: 2px solid rgba(0, 0, 0, 0.1) !important;
        border-bottom: 4px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0 8px 8px 0;
        transition: all .5s;

        &:hover, &:focus{
          opacity: 1;
          outline: 2px solid inherit;
          transition: all .5s;
          border-color: inherit !important;

        }
        &:first-of-type{
        border-radius: 8px 0 0 8px;
        border-right: 0 !important;
        margin-right: -2px;
        &:hover, &:focus{
          border-right: 2px solid !important;
        }
        }
      }
    }
  }


  // button styles
  button.previous{
      background: transparent !important;
      width: min-content;
      transition: all .2s;
      padding: 16px 16px;
      opacity: 0.7;
      border: 0;
      cursor: pointer;
      user-select: none;

      &:hover{
        transition: all .2s;
        transform: scale(1);
        opacity: 1;
        box-shadow: none;
      }
    }
    .button--submit{


    }




.button--submit:hover {

}



    .button--submit, .button--next{
      transition: all .2s;
      // box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      appearance: button;
      border-radius: 8px;
      // border-width: 0 0 6px;
      border:none;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 0;
      outline: none;
      overflow: visible;
      padding: 13px 16px;
      text-align: center;
      touch-action: manipulation;
      transform: translateZ(0);
      transition: all .2s;
      user-select: none;
      -webkit-user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;
      
      &:hover{
        transition: all .25s;
        filter: brightness(1.1);
        border: solid transparent;
        border-width: 0 0;
        -webkit-filter: brightness(1.1);
        transform: scale(1.02);
      }
    }


    // media on the left

        .container__item--content--animation-title{
        }

        &.formtype__classic{
          .container__item--media {
            div{
            max-height: 380px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
          }}
        }
        &.formtype__slideshow{
          .container__item--media {

          }
        }
        .signature__area{
          backdrop-filter: blur(16px);
        }
        .signature-canvas{
          transition: all .5s;
          border-radius: 32px;
          &:hover, &:focus{
            transition: all .5s;
            opacity: 1;
          }
        }
        //fileuploader
        .dropzone{
          // border: 2px dashed;
          opacity: .5;
          &:hover, &:focus{
            transition: all .5s;
            opacity: 1;
            filter: brightness(.8);
          }
        }
        // datepicker
        .interactive_block{
          .react-datepicker-wrapper {
            width: 100%;
            position: relative;
            svg {
              position: absolute;
              top:8px;
              right:0;
            }
          }

          .react-datepicker__day{
            border-bottom: 3px solid;
            width: max-content !important;
            margin: 4px auto;
            min-width: 32px !important;
          }
          .react-datepicker__day:hover{
            border-radius: 8px;
          }
          .field__input,.react-datepicker-wrapper input {
                padding: 16px 20px;
                display: flex;
                border:none;
                margin-bottom: 12px;
                transition: .75s all;
                border-radius: 8px;
                backdrop-filter: blur(16px);

                    &:focus{
                        opacity: 1;
                        transition: .15s all;
                        border:2px solid;
                    }
                    &::placeholder{
                      opacity: .75;
                      color: inherit;
                    }
            }
        }
        .input__icon{
            svg{
                padding-right: 18px;
            }
        }
    }



.container__item--media--loader{
  width: 100%;
  backgoriginal-color: inherit;
}
