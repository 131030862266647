

.pageAdmin{
  background-color: #e5deed;
  opacity: 1;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #f7f3fa3b 39px ), repeating-linear-gradient( #e2e0e91f, #e2e0e969 );
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    box-sizing: border-box;
  }
  

  .form {
    width: 100%;
    label{
      font-size: 15px;
      display: block;
      letter-spacing: -.5px;
      margin-top: 8px;
      opacity: 0.5;
    }
    input, textarea {
        background-color: var(--grey-60);
        color:var(--white-100);
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
    }
  }
  .formGroupDesc{
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .descriptionTextarea {
    height: 200px;
  }
  
  button{
    padding:12px;
    background-color: white;
    border-radius: 12px;
    border:none;
    font-family: 'Archia';
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .button{
    display: block;
    font-family: 'Archia';
    font-size: 16px;
    font-weight: bold;
    color: var(--white-100);
    background-color: rgb(56 32 98);
    width: max-content;
    padding:var(--xs-spacing);
    border: none;
    border-radius: 12px;    
    cursor: pointer;

  }
  .sidebar {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    right: 0;
    color: white;
    backdrop-filter: blur(20px);
    box-sizing: border-box;
    width: 500px; /* Adjust the width as needed */
    background-color: rgba(14, 7, 23, 0.838); /* Background color of the sidebar */
    box-shadow: -2px 0 4px rgba(255, 255, 255, 0.1); /* Add a shadow to the sidebar */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
    transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
    transform: translateX(100%); /* Start with the sidebar off-screen */
    z-index: 1000; /* Ensure it's above other content */
    transform: translateX(0); /* Slide in the sidebar when it's open */
  }
  h1{
    font-size: 32px;
    letter-spacing: -1px;
  }
  h2{
    font-size: 18px;
    text-align: left;
  }
  
  /* Style for a close button in the sidebar */
  .sidebarclosebutton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    padding:4px 8px;
    border-radius: 20px;
    cursor: pointer;
    color: #fff; /* Color of the close button */
  }

}
