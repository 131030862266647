@import '../mixin.scss';

.container {
    display: grid;
    grid-template-columns: 200px auto;
    color: var(--white-60);

    @include devices(mobileandtablet) {
        grid-template-columns: unset;
    }
}

.nav {
    background-color: var(--grey-60);
    border-radius: 12px 0 0 12px;
    display: grid;
    grid-template-rows: auto min-content;

    @include devices(mobileandtablet) {
        border-radius: 12px 12px 0 0;
    }
    .media_list{
        justify-content: left;
        display: grid;
        height: min-content;
        cursor:initial;
    }
    ul {
        display: block;
        flex-direction: column;
        padding: var(--s-spacing);
        box-sizing: border-box;
        gap: var(--xxs-spacing);

        li {
            display: flex;
            column-gap: var(--xxs-spacing);
            font-size: var(--s-text);
            padding: 0;

            font-weight: bold;
            letter-spacing: -.5px;
        }

    }

}

.active {
    font-weight: 700;
    color: var(--white-100);
}

.media_logo {
    max-width: 17px;
    display: grid;

    img {
        width: 100%;
    }
}

.content {
    padding: var(--s-spacing);
    background-color: var(--grey-30);
    box-sizing: border-box;
    border-radius: 0 12px 12px 0;
    max-height: var( --modal-height);
    overflow: auto;

    @include devices(mobileandtablet) {
        border-radius: 0 0 12px 12px;
    }

    header {
        font-size: var(--s-text);
        font-weight: 600;
        margin-bottom: var(--xs-spacing);
        color: var(--white-100);
        p{
            font-size: var(--xs-text);
            color: var(--white-60);
        }
    }

    input {
        background-color: var(--white-100) !important;
    }

    @include devices(desktop) {
        overflow: auto;
    }

    .gallery_search {
        margin-bottom: var(--xs-spacing);

        input {
            width: 100%;
            padding: var(--xxs-spacing);
            box-sizing: border-box;
        }

    }
}

.gallery_search_instruction{
    text-align: right;
}

.current_img {
    padding: var(--s-spacing);
    display: grid;
    row-gap: var(--xxs-spacing);

    header {
        font-weight: 600;
        font-size: var(--xs-text);
        user-select: none;
        letter-spacing: -.5px;
        cursor:auto;
        i{
            letter-spacing: .5px;
            display: block;
            margin-top: 4px;
            padding:var(--xxxs-spacing);
            background-color: var(--white-08);
            color:var(--white-100);
            font-style: normal;
            box-sizing: border-box;
            text-transform: uppercase;
            font-size: 10px;
            width: max-content;
            border-radius: var(--xs-radius);
        }
    }

    img,
    video {
        width: 100%;
        border-radius: var(--s-radius);
    }

    a {
        color: var(--white-60);

        &:hover,
        &:focus {
            color: var(--white-100);
        }
    }
}

.delete_current_img {
    color: var(--red-100);
    position:absolute;
    right:8px; 
    font-weight:bold; 
    top:8px;
    &:hover,
    &:focus {
        font-weight: 700;
    }
}




// InlineEditorGallery.module.scss
.my_masonry_grid {
    display: flex;
    width: auto;
    gap: 8px;
}

.my_masonry_grid_column {

    background-clip: padding-box;

    img {
        width: 100%;
    }
}

.gallery_item {
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--xxs-spacing);
    row-gap: var(--xxs-spacing);
    position: relative;

    .link {
        display: none;
    }

    &:hover,
    &:focus {
        .link {
            display: block;
        }
    }
}



.link {
    a {
        color: var(--white-60);

        &:hover,
        &:focus {
            color: var(--white-100);
        }
    }

    font-size: var(--xs-text);
    position: absolute;
    left: 8px;
    bottom: 8px;
}

  .add_url{
    display: grid;
    gap:var(--xs-spacing);
    input{
        width: 100%;
        padding: var(--xxs-spacing) !important;
        border-radius: var(--xs-radius) !important;
        font-size: var(--xs-text);
        // background-color: var(--white-36) !important;
        // color: var(--white-100) !important;
    }
    button{
        background-color: var(--primary-color-1000);
        border: none;
        border-radius: var(--xs-radius);
        font-family: inherit;
        font-size: var(--xs-text);
        font-weight: bold;
        color: var(--white-100);
        white-space: pre;
        padding-left: var(--s-spacing);
        padding-right: var(--s-spacing);
    }
  }


.instructions{
    font-size: var(--xs-text);
    text-align: left;
    h2{
        color: var(--white-100);
        padding: var(--s-spacing) 0 var(--xs-spacing);
        font-size: var(--xs-text);
        font-weight: bold;
        max-width: 72%;
        margin: auto;
    }
    ol{
        font-size: var(--xs-text);
        max-width: 72%;
        margin: auto;
        text-align: left;
        li{
            list-style: decimal;
            padding-bottom:var(--xxs-spacing);
            margin-left: var(--xs-spacing);
        }
    }
}
.gallery_area{
    border-radius: var(--xs-radius);
    border: 2px solid var(--white-08);
    padding: var(--xs-spacing) var(--xs-spacing);
}

.gallery_container_area{
    display: flex;
    align-items: flex-start;
    gap: var(--xs-spacing);
    padding-bottom: 16px;
    border-bottom: 2px solid var(--white-08);
    @include devices(mobile){
        display: block;
        input{
            font-size: 16px;
        }
    }
    .video_preview{
        max-width: 500px;
        width: 100%;
        @include devices(mobile){
           margin-top: var(--xs-spacing);
        }
    }
    form{
        width: 100%;
        height: unset !important;
        padding: 0 !important;
        .add_url{
            gap: var(--xxxs-spacing);
            label, input, button{
                padding: 0;
                font-size: var(--xs-text);
            }
            input{
                background: var(--white-100);
            }
            label{
                color:var(--white-100);
                user-select: none;
            }
            button{
                padding: var(--xxxs-spacing) var(--xxs-spacing);
            }
  
        }
    }
}



.bg_img_gallery{
    padding-right: var(--xxxs-spacing);
    box-sizing: border-box;
}


.add_url_input{
    position: relative;
    button, & >span{
        right:4px;
        bottom:4px;
        position: absolute;
    }
}

.gallery_search{
    position: relative;
    // form.gallery_form{
    //     height: unset;
    //     padding: 0;
    // }
    span{
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        box-sizing: border-box;
        height: fit-content;
        border-radius: var(--xs-radius);
        svg{
            fill: var(--grey-100)
        }
    }
}

.gallery_search_wrapper{
    margin-bottom: var(--xs-spacing);
    .gallery_search{
        margin-bottom: 0;
    }
    &> p{
        color: var(--white-100);
        text-align: right;
        font-size: var(--xs-text);
        margin-top: var(--xxs-spacing);
    }
}

