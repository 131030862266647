@import '../mixin.scss';

.container{
    padding: var(--s-spacing);
    box-sizing: border-box;
}

.form_control{
    .form_meta{
        display: grid;
        grid-template-columns: auto max-content;
        align-items: center;
        gap: var(--xxs-spacing);
        // padding: var(--xxs-spacing);
        // background-color: var(--grey-03);
        border-radius: var(--s-radius);
        margin-bottom: var(--xs-spacing);
        color:var(--grey-60);
        span{
            display: flex;
            align-items: center;

        }
        svg {
            height: 20px;
        }
        user-select: none;
        .share {

        }
        .back_to_hub{
            cursor: pointer;
            i {
                opacity: 0;
                font-style: normal;
                transition: all .3s;
                font-size:var(--xs-text);
            }
            &:hover{
                i {
                    opacity: 1;
                    transition: all .3s;
                }
            }
        }
        .share{
                transition: all .3s;
                cursor: pointer;
                font-size:var(--xs-text);
                font-weight: bold;
                padding: var(--xxxs-spacing) var(--xxs-spacing) var(--xxxs-spacing) 0;
                border-radius: var(--xs-radius);
                display: flex;
                gap: var(--xxxs-spacing);
                svg {
                    width: 0;
                    height: 0;
                    opacity: 0;
                }
                &:hover{
                    transition: all .3s;
                    padding: var(--xxxs-spacing) var(--xxs-spacing);
                    background-color: var(--grey-100);
                    color: var(--white-100);
                    svg {
                        width: 14px;
                        height: 14px;
                        opacity: 1;
                    }
                }
        }
    }

    header{
        display: grid;
        grid-template-columns: auto 50px ;
        grid-gap: 4px;
        svg{
            font-size: 24px;
            fill: var(--grey-100);
        }
    }
}

.add_block_button, 
.add_style_button{
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover, &:focus{
        border: 2px solid var(--grey-100);
    }

    &.active{
        border: 2px solid var(--grey-100);
    }
}

.active{
    border: 2px solid var(--grey-100);
}

.add_style_button{
    justify-content: center;
}

.add_block_category{
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 8px;
    justify-content: center;
    text-align: center;
    padding-top: var(--s-spacing);
    li{
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 22px auto;
        border-radius: 8px;
        padding: 4px ;
        box-sizing: border-box;
        min-width: 45px;
        cursor: pointer;
        &:hover, &.active{
            background-color: var(--grey-08);
        }
        span{
            font-size: var(--xs-text);
            color: var(--grey-65);
            font-weight: 700;
            padding-top: 4px;
            letter-spacing: -.5px;
        }
        svg{
            margin: auto;
            color: rgb(194,132,96);
        }
    }
}


.add_block_items{
    max-height: 270px;
    overflow: auto;
    background-color: var(--grey-08);
    padding: var(--s-nav-spacing);
    margin-top: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}

.active_categorygroup{
    background-color: var(--grey-08);
}


.section_seperator{
    padding-top: var( --xs-spacing) ;

    &.with_line{
        padding-top: 4px ;
        padding-bottom: 4px ;
        border-top: 1px solid var(--grey-08);
    }
}
.share_modal__content{
    background-color: var(--white-100);
    min-height: 452px;
    border-radius: var(--s-radius);
    gap: var(--xs-spacing);
    article{
        padding:0 var(--sm-spacing) var(--s-spacing);
        border-bottom: 2px solid var(--grey-08);
        margin-top: var(--s-spacing);
        &:last-of-type{
            border-bottom: 0;
        }
        &:first-of-type{
            margin-top: 0;
        }
        &.admin {
            padding: 0 0;
            h2 {
            padding:0 var(--sm-spacing);
            }
        }
        .add_member{
            padding: var(--xs-spacing) var(--sm-spacing) var(--s-spacing);
            background-color: var(--grey-03);
            display: grid;
            gap: var(--xs-spacing);
            .error_admin{
                background-color: var(--primary-color-20);
                display: flex;
                align-items: center;
                gap:var(--xxxs-spacing);
                margin-top: var(--xxs-spacing);
                padding: var(--xxs-spacing);
                border-radius: var(--xs-radius);
                user-select:none;
                color:var(--primary-color-1000);
            }
            .member_input{
                display: flex;
                gap: var(--xxs-spacing);
                input{
                    width: 100%;
                    padding: var(--xs-spacing);
                    background-color: var(--white-100);
                    font-size: var(--xs-text);
                    border: 2px solid var(--grey-08);
                }
            }
            h5{
                margin: 0;
                font-size: var(--xs-text);
                letter-spacing: -.5px;
                font-weight: normal;
                color:var(--grey-60);
            }
        }

    }
    button{
        background-color: var(--white-100);
        border:none;
        border-radius: var(--xs-radius);
        color:var(--grey-100);
        border: 2px solid var(--grey-100);
        font-family: 'Archia';
        padding: var(--xxs-spacing);
        user-select: none;
        font-weight: bold;
        cursor: pointer;
    }
    .switcher{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: max-content;
        background-color: var(--grey-03);
        border-radius: 20px;
        padding: var(--xxxs-spacing);
        margin-top: var(--xs-spacing);

        .switcher_option{
            padding: var(--xxxs-spacing) var(--xxs-spacing);
            display: flex;
            align-items: center;
            border-radius: var(--s-radius);
            cursor: pointer;
            letter-spacing: -.5px;
            color:var(--grey-60);
            font-size:var(--xs-text);
            font-weight: bold;
        }
        .active{
            background-color: var(--white-100);
            color: var(--grey-100);
            box-shadow: var(--s-shadow);
            font-weight: bold;
            border: 2px solid var(--grey-08);
            padding: var(--xxxs-spacing) var(--xxs-spacing);
        }

    }
    .share_modal__url{
        display: grid;
        width: 100%;
        grid-template-columns: auto max-content;
        // background-color: var(--grey-08);
        padding:var(--xxs-spacing);
        box-sizing: border-box;
        border-radius: var(--xs-radius);
        // margin-top: var(--xxs-spacing);
        border: 2px solid var(--grey-08);
        .link_input{
            font-size: var(--xs-text);
            padding: var(--xxxs-spacing);
            background-color: transparent;
            color:var(--grey-30);
            border-radius: 0;
            &:focus{
                border:none;
            }
        }
    }
    textarea{
        background-color: var(--grey-03);
        color:var(--grey-30);
        font-size: var(--xs-text);
        padding:var(--xxs-spacing);
        border-radius:var(--xs-radius);
        margin-top: var(--s-spacing);
        width: 100%;
        height: 72px;
        margin-bottom: var(--xxxs-spacing);
    }

    h1{
        font-size: var(--m-text);
        color: var(--grey-100);
        margin: 0;
        padding:var(--sm-spacing) var(--sm-spacing) var(--s-spacing);
    }
    h2 {
        font-size: var(--s-text);
        font-weight: bold;
        display: flex;
        align-items: center;
        color: var(--grey-100);
        letter-spacing: -.5px;
        padding-bottom: 0;
        user-select: none;
        &.collapsible{
            cursor: pointer;
        }
        svg{
            margin-right: var(--xxs-spacing);
            // color: var(--white-100);
            // background-color: var(--grey-100);
            // padding: var(--xxxs-spacing);
            // border-radius: var(--xs-radius);
        }
    }
    .form_members{
        display: flex;
        width: max-content;
        max-width: 100%;
        padding-left: var(--sm-spacing);
        gap: var(--xs-spacing);
    }
    .form_members__wrapper{
        overflow-x: auto;
        width: 100%;
    }
    .member, .create_member{
        width: 88px;
        padding: var(--xs-spacing) 0;
        display: grid;
        justify-content: center;
        align-items: flex-start;
        grid-template-rows: max-content min-content min-content;
        .member_icon{
            width: 72px;
            height: 72px;
            background-color: var(--grey-08);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

        }
        i {
            margin: auto;
            color: var(--grey-30);
            font-style: normal;
            font-size: var(--xs-text);
            letter-spacing: -.5px;
        }
        h4{
            margin: var(--xxxs-spacing) 0 0;
            color:var(--grey-60);
            font-size: var(--xs-text);
            font-weight: normal;
            text-align: center;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis; 
        }
    }
    .member{
        position: relative;
        cursor: pointer;
        justify-content: center;
        .member_control{
            margin: auto;
            display: grid;
        }
        .remove {
            opacity: 0;
            text-align: center;
            // position: absolute;
            // bottom: 0;
            // left: 50%;
            // transform: translateX(-50%); 
        }
        &:hover{
            h4{
                color:var(--grey-100);
            }
            .remove{
                display: flex;
                opacity: 1;
                font-size: var(--xs-text);
                color:var(--red-100);
                text-align: center;
            }

        }
    }
    .create_member{
        cursor: pointer;

        .member_icon{
            background-color: transparent;
            border: 2px solid var(--grey-08);
            transition: all .5s;
            width: 68px;
            height: 68px;
            svg{
                width: 32px;
                height: 32px;
                color: var(--grey-30);
                transition: all .5s;

            }
            &:hover{
                border: 2px solid var(--grey-100);
                transition: all .5s;

                svg{
                    color: var(--grey-100);
                    transition: all .5s;
                }
            }
        }
    }
}
.preview_menu{
    display: flex;
    align-self: center;
    font-size: var(--xs-text);
    padding: var(--xxs-spacing) 0 0;
    gap: var(--xxxs-spacing);
    color: var(--primary-color-1000);
    font-weight: bold;
    svg {
        font-size: var(--s-text);
    }
}
.link_section{
    display: grid;

    gap: var(--xs-spacing);
    align-items: start;
    padding-top: var(--xxs-spacing);
    @include devices(tabletanddesktop){
        grid-template-columns: auto max-content;
    }
    h3{
        font-size: var(--xs-text);
        text-transform: uppercase;
        // font-weight: normal;
        // letter-spacing: .15px;
        padding: var(--xxxs-spacing) 0 var(--xxs-spacing);
        color: var(--grey-60);
    }
    .qr_code{
        canvas{
            width: 80px !important;
            height: 80px !important;
        }
    }

  }
  .edit_form_detail{
    h1{
        font-size: var(--s-text) !important;
    }
    label{
        padding: var(--xs-spacing) 0 var(--xxxs-spacing);
        display: block;
        font-size: var(--xs-text);
    }
    button{
        background: var(--grey-100);
        color: var(--white-100);
        font-size: var(--xs-text);
        font-family: 'Archia';
        border:none;
        border-radius: var(--xs-radius);
        padding: var(--xxs-spacing);
        margin-top: var(--xxs-spacing);
        font-weight: bold;
    }
    input, textarea{
        background-color: var(--grey-08);
        font-size: var(--xs-text);
        width: 100%;
        color:var(--grey-60);
        border-radius: var(--xs-radius);
        padding:var(--xxs-spacing);
    }
  }
  .clearButton{
    display: block;
  }
  .actions{
    display: grid;
    grid-template-columns: max-content auto;
    gap:var(--xs-spacing);
    align-items: center;
    margin-top: var(--xxs-spacing);
    button{
        margin:0;
    }
  }