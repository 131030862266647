@import '../mixin.scss';

.navigation_container {
  position: relative;
  z-index: 1;
}

.navigation {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    height: 92px;
    align-items: center;
    padding: 0 var(--sm-spacing);
    box-sizing: border-box;
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgba(241, 235, 231, 0.5);
    backdrop-filter: blur(16px);
    z-index: 0;
  &.navigation_notauthenticated{
    grid-template-columns: max-content auto max-content;
    gap: var(--xxs-spacing);
    @include devices(desktop){
    grid-template-columns: auto max-content;

    }

    .actions{
      display: flex;
      gap: var(--xxs-spacing);
    .action,.action:visited,.action:active,.action:link{
      padding: var(--xs-spacing) var(--s-spacing);
      @include devices(mobile){
        padding: var(--xxs-spacing);
      }
      box-sizing: border-box;
      border-radius: var(--xs-radius);
      border: 2px solid var(--grey-08);
      font-weight: bold;
      color: var(--grey-100);
      cursor: pointer;
      &:hover{
        transform: scale(1.1);
        transition: all .5s;
      }
      &.primary{
        border:none;
        background-color: var(--grey-100);
        color:var(--white-100);
      }
    }}
  }

    @media only screen and (min-width: 767px) {
      grid-template-columns:  max-content auto max-content;
      column-gap: var(--s-spacing);
      z-index: 1;
      width: 100%;
      }

  .menu {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: var(--s-spacing);
    justify-content: center;

    @media only screen and (max-width: 767px) {
      display: none;
      flex-direction: column;
      align-items: center;
    }

    a,
    a:active,
    a:visited {
      color: var(--grey-60);
      font-weight: bold;
    }

    a:hover {
      color: var(--grey-100);
    }

    .menu__item {}

  }

  .menu__item__active{
    a{
      color: var(--grey-100);
      font-weight: 700;
    }

  }

  .menu__createform {
    display: none;

    @include devices(desktop) {
      display: unset;
    }
  }

  .menu__mobi {
    background-color: var(--white-100);
  }

  .navigation__user {
    display: grid;
    justify-content: flex-end;
    position: relative;
    grid-template-columns: max-content max-content;
    align-items: center;
    gap: var(--xxs-spacing);
    .avatar {
      width: 48px;
      height: 48px;
      &.empty{
        background-color: var(--primary-color-20);
        text-transform: uppercase;
      }
      color: var(--primary-color-1000);
      // padding: 8px;
      border-radius: var(--xs-radius);
      font-weight: bold;
      display: grid;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: auto;
      border: 2px solid var(--grey-03); 
      // border: 2px solid var(--white-100);
      transition: all .5s;

      &:hover {
        border-color: var(--primary-color-1000);
        transition: all .5s;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }

    .user_menu {
      box-shadow: var(--s-shadow);
      padding: var(--xs-spacing) var(--xs-spacing);
      background-color: var(--white-100);
      border-radius: var(--s-radius);
      position: absolute;
      top: 60px;
      left: -68px;
      width: 120px;
      border: 1px solid var(--grey-08);
      animation: slideIn 0.5s ease-in-out;
      display: grid;
      gap: var(--xxxs-spacing);
      letter-spacing: -.5px;

      a,
      a:link,
      a:active,
      a:visited {
        font-weight: bold;
        color: var(--grey-60);
        transition: all .25s ease-in-out;

        &.premium {
          svg {
            font-size: 14px;
            color: var(--primary-color-1000);
          }
        }
      }

      a:hover {
        color: var(--grey-100);
        transition: all .25s ease-in-out;
      }

    }
  }

  .hamburger {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
      display: none;
    }

    svg {
      font-size: 28px;
    }

    &.active {
      .hamburger__line {
        background-color: transparent;

        &:before,
        &:after {
          background-color: var(--grey-100);
        }
      }
    }
  }
}

.menu__mobi {
  width: 100vw;
  background-color: var(--grey-100);
  padding: var(--sm-spacing) var(--m-spacing);
  box-sizing: border-box;
  display: grid;
  gap: var(--xxs-spacing);
  position: sticky;
  z-index: 3;

  a,
  a:active,
  a:link,
  a:visited {
    color: var(--white-100);
    font-weight: bold;
    font-size: var(--s-text);
  }

  button {
    background-color: var(--primary-color-1000);
  }

  .menu__createform {
    position: sticky;
  }

  .modal {
    z-index: 9;
    position: fixed;
  }
}

/* styles.module.css */

.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  position: fixed;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: var(--white-100);
  padding: var(--s-spacing);
  border-radius: var(--s-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  margin: var(--l-spacing) auto;
  z-index: 1;

  h2 {
    font-size: var(--s-text);
    letter-spacing: -.5px;
    padding-bottom: var(--s-spacing);
  }
}


.modal form article {
  display: block;
  font-size: var(--s-text);
  color: var(--grey-60);
  letter-spacing: -.5px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: var(--xxs-spacing);
  border-bottom: 1px solid var(--grey-08);
  padding: var(--xxs-spacing);
  font-size: var(--xs-text);

  &:last-of-type {
    margin-bottom: 0;
    border: none;
  }
}

.modal .userEditForm {
  border: 1px solid var(--grey-08);
  border-radius: var(--s-radius);
  margin-bottom: var(--s-spacing);
}

.modal form input[type="text"],
.modal form input[type="email"] {
  width: 100%;
  padding: 2px;
  background-color: var(--grey-08);
  // border-bottom: 1px solid #ccc;
  border-radius: 4px;
}

.modal form button[type="submit"] {
  background-color: var(--grey-100);
  font-family: 'Archia';
  font-weight: bold;
  padding: var(--xs-spacing);
  display: block;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: max-content;

}

.modal form button[type="submit"]:hover {
  background-color: var(--primary-color-1000);
}

.modal-overlay.hidden {
  display: none;
}

.modal_close {
  position: fixed;
  width: max-content;
  right: 0;
}

.avatars_wrapper {
  // padding: var(--xxs-spacing) var(--s-spacing);
  padding-bottom: var(--s-spacing);
  font-size: var(--xs-text);
  color: var(--grey-60);
}

.avatars {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  background-color: var(--grey-08);
  padding: var(--xxs-spacing) var(--m-spacing);
  border-radius: var(--s-radius);

  .avataritem_wrapper {
    align-items: center;
    display: grid;
    padding: var(--xxs-spacing);
    gap: var(--xxxs-spacing);
    justify-content: center;
  }

  .avataritem {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: var(--s-radius);
    background-color: var(--grey-08);
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.avatar_picker {
  display: grid;
  align-items: center;

  // grid-template-columns: 1fr 3fr;
  a {
    font-size: var(--xs-text);
    color: var(--primary-color-1000);
    letter-spacing: -.5px;
    display: block;
    cursor: pointer;
    padding: var(--xxs-spacing) 0;
    text-align: center;
    font-weight: bold;
  }

  .avatar {
    margin: 12px auto 4px;
    height: 68px !important;
    width: 68px !important;
    font-size: 32px;
  }
}
.notification_list{
 position: absolute;
 top:56px;
 right: 0;
 width: 320px;
 max-height: 80vh;
 overflow-y: auto;
 background-color: var(--white-100);
 backdrop-filter: blur(16px);
 border: 1px solid var(--grey-08);
 box-shadow: var(--l-shadow);
//  padding: var(--xs-spacing) var(--s-spacing);
 display: grid;
 border-radius: var(--s-radius);
.notification_empty{
  h2,p{
    font-size: var(--xs-text);
    color:var(--grey-30);
  }
  padding: var(--m-spacing) var(--s-spacing);
  display: grid;
  text-align: center;
  gap: var(--xxxs-spacing);
}
 .notification_header{
  font-weight: bold;
  letter-spacing: -.5px;
  font-size: var(--xs-text);
  padding: var(--xs-spacing) var(--s-spacing);
  border-bottom: 2px solid var(--grey-08);
 }
 .notification_footer{
  font-weight: bold;
  letter-spacing: -.5px;
  font-size: var(--xs-text);
  padding: var(--xs-spacing) var(--s-spacing);
  span{
    color: var(--grey-30);
  }
  a{
    cursor: pointer;
    color:var(--primary-color-1000);
  }
 }
 .notification_item{
    color: var(--grey-30);
    padding: var(--xxs-spacing) var(--s-spacing);
    display: grid;
    gap: var(--xxs-spacing);
    border-bottom: 1px solid var(--grey-08);
    font-size: var(--xs-text);
    letter-spacing: -.5px;
    cursor: pointer;
    transition: all .3s;

    &:hover{
      background-color: var(--grey-03);
      transition: all .3s;
    }
    &:last-of-type{
    border-bottom: none;
    }

    h2{
      color: var(--grey-60);
      font-weight: normal;
      font-size: var(--xs-text);
      line-height: var(--m-text);
    }
    a{
      color: var(--primary-color-1000);
    }
 }
}
.notification_count{
  font-weight: bold;
  position: relative;
  // background-color: var(--primary-color-1000);
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid transparent;
  transition: all .3s;
  min-width: 28px;
  min-height: 28px;
  border-radius: var(--xs-radius);
  font-size: var(--s-text );
  cursor: pointer;
  color: var(--grey-30);
  &.active{
  color: var(--grey-100);
  }
  &:hover{
    transition: all .3s;
    color: var(--grey-60);

  }
}
.unread{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: var(--red-100);
  margin-right: 4px;
}
.mobileNav{
  background-color: var(--grey-08);
  padding: var(--xs-spacing) var(--m-spacing);
  a {
    color: var(--grey-100);
  }
}