@import 'mixin.scss';

//-----------------------------------
//Al this field is within the block realm
//Style apply on all formStyle of every fields that belong within the block
//-------------------------

//Hero text
.herotext{
    position: relative;
    &.slideshow_no_img{
        .block_item_inner_container{
            max-width: var(--m-block);
          }
    }
}

  //Input - long Answer field
  .longAnswer{
    .field__input{
      min-height: 132px;
    }    
  }


.nps, .scale{
     .block_item_content{
        @include devices(mobileandtablet){
            // padding-left: 20px;
            // padding-right: 20px;
            padding: 0 !important;
            box-sizing: border-box;
        }
    }
    }    

