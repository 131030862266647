@import 'mixin.scss';

.block_classic_skeleton {

    // overflow: auto;
    @include devices(desktop) {
        padding-bottom: 160px;

    }

    .block_logo {
        width: 100%;
        box-sizing: border-box;
    }



    .full_screen_image {
        max-width: unset;
    }

    .block_edit_inner {
        grid-template-columns: auto var(--m-spacing);
        grid-template-areas: 'block_item_drag block_item';
        grid-template-columns: var(--m-spacing) auto;
        // padding-left: var(--m-spacing);
        // padding-right: var(--m-spacing);
        // box-sizing: border-box;

        @include devices(mobile) {
            width: fit-content;
            grid-template-areas: 'block_item_drag block_item block_item_nav';
            grid-template-columns: var(--m-spacing) auto var(--m-spacing);
        }
    }

    .block_item {
        @include devices(mobileandtablet) {
            min-width: unset;
        }
    }

    @include devices(desktop) {
        .block_item_media {
            grid-area: block-media;
        }

        .block_item_content {
            grid-area: block-content;
        }

        .block_right_field_img {
            grid-template-areas: 'block-content block-media';
            display: grid;
        }


        .block_left_field_img {
            grid-template-areas: 'block-media block-content';
            display: grid;
        }
    }

    // .block_item_inner_container{
    //     @include devices(tabletanddesktop){
    //         display: grid;

    //     }
    // }




}

.block_footer_classic {
    .block_footer_container {
        max-width: var(--l-block);
        margin: auto;

        @include devices(mobileandtablet) {
            padding-bottom: var(--l-spacing);
            padding-right: 16px;
            box-sizing: border-box;
        }

        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0 var(--sm-spacing) var(--sm-spacing);
        box-sizing: border-box;

    }

    .block_footer_inner_container,
    .block_error {
        display: grid;
        justify-content: flex-end;
    }

    .block_footer_buttons_wrapper,
    .block_error_inner,
    .block_submit_button {
        max-width: var(--submit-btn-width);

        @include devices(desktop) {
            width: var(--submit-btn-width);
        }
    }

    .block_footer_caption {
        padding-bottom: 0;
    }

    // .block_footer_inner_container{
    //     @include devices(mobileandtablet){
    //         display: block;
    //         margin-bottom: var(--xxs-spacing);

    //     }
    // }


    .block_footer_button {
        padding: 16px 40px;
        box-sizing: border-box;
        align-items: center;
    }

    .block_error {
        text-align: right;
    }
}

//-----------------------------------
//Editor page
//Specially for editor page
//-------------------------
.editor_page_skeleton {
    .block_classic_skeleton {
        .block_footer {
            padding-bottom: 0;
        }
        .block_logo.block_logo_classic {
            @include devices(mobile) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .block_edit_inner{
            @include devices(desktop) {
                padding-left: var(--sm-spacing);
                padding-right: var(--sm-spacing);
        
                .block_right_field_img {
                    display: flex;
                    flex-flow: row-reverse;
                    overflow: hidden;
                }
        
        
                .block_left_field_img {
                  display: flex;
                  overflow: hidden;
                }
            }
        }
    }


}

//-----------------------------------
//Form page
//Similar but minus all the block_edit_w_nav and block_edit_inner
//-------------------------
.form_page_skeleton {

    //--------------
    //Modification on Form
    //--------------
    .block_footer {
        padding-bottom: 0;
        user-select: none;
        -webkit-user-select: none;

        button {
            user-select: none;
            -webkit-user-select: none;
        }
    }

    .block_caption {
        padding-bottom: 0;
    }

    .block_classic_skeleton {
        .block_item_content, .block_item_media{
            flex: 1
        }
        @include devices(mobile) {
            .block_item {
                padding-left: 0;
                padding-right: 0;
            }

            .block_item_content {
                padding-right: var(--m-spacing);
                padding-left: var(--m-spacing);
                margin: unset;
            }
        }
        

    }
}