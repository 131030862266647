@import '../mixin.scss';

.page__form,
.page__editor {

    .option__item--description {
        display: none;
    }

    .option__item--slider {
        // border-width: 2px;
        border-style: solid;
        // min-width: 200px;
    }

    .option__item {
    
        position: relative;
        cursor: pointer;
        display: grid;
        padding-bottom: var(--m-spacing);
        margin-bottom: var(--m-spacing);

        @include devices(mobileandtablet) {
            padding-bottom: var(--m-spacing);
        }

    }



    .carousel-slider {
        .option__item--indicator {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            display: inline-block;
            margin-left: 16px;
            text-indent: -9999px;
            cursor: pointer;
        }

        .slide {
            display: grid;
            align-items: flex-start;
            // padding: var(--xs-spacing);
            box-sizing: border-box;
            transform: scale(.8);
            margin-top: -20px;

            img,
            video {
                // width: auto;
                // width: -webkit-fill-available;
                // margin: auto;
                width: 100%;
                object-fit: cover;
            }

            &.selected {
                // background-color: red;
                transform: scale(1);
                margin-top: 0;
                opacity: 1;

                .option__item {
                    grid-template-rows: var(--option-slider-img-max-height) auto;
                }

                .option__item--description {
                    display: block;
                }

            }
        }
    }
}

.page__editor{
    .carousel-slider{
        padding-bottom: var(--m-spacing);
    }
}


.multichoice {
    .slider-wrapper {
        display: block;
    }
}

.page__form {
    .carousel-root {
        max-width: var(--m-block);
        margin: auto;
    }
}

.option_slider_wrapper_editor {
    .slider-wrapper {
        @include devices(desktop) {
            overflow: auto !important;
        }
    }

    .carousel-slider {
        .slide {
            cursor: pointer;
            &:hover{
                nav{
                transition: all .3s;
                visibility:visible;
                opacity: 1;
                }
                // border:2px solid var(--grey-08);
                // border-radius: 8px;
                // transition: all .15s;
            }
        }

        .slide {
            padding: 0;
            display: block;
            // transform: scale(1);
            // margin-top: 0;
            // padding-left: var(--m-spacing);
            // padding-right: var(--m-spacing);
            // box-sizing: border-box;
            transform: scale(.9);
            margin-top: -10px;

            .option__item {
                display: block;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .option_nav {
                visibility: hidden;
                opacity: 0;
                transition: all .3s;
                svg{
                font-size: 16px;
                }
            }

            .option_item_on_drag {
                &>div>svg {
                    display: none;
                }
            }

            .option_nav{
                display: none;
            }

            &.selected {
                padding-left: 0;
                padding-right: 0;
                box-sizing: border-box;
                // border:2px solid var(--grey-08);
                // border-radius: 8px;
                .option_item_wrapper {
                    display: flex;
                }
                .option_nav{
                    display: block;
                }
                &:hover{
                        .option_nav{
                            visibility: visible;
                    }
                }
                .option__item {
                    // display: grid;
                    // grid-template-columns: auto min-content;

                    // @include devices(mobile) {
                    //     grid-template-columns: unset;

                    // }

                }


                .option_item_on_drag {
                    &>div {
                        display: block;
                    }
                }

                .option_item_on_drag {
                    &>div>svg {
                        display: block;
                    }
                }
            }
        }
    }

    @include devices(mobile) {

        .carousel .control-dots {
            width: auto;
            left: 0;
            right: 0;
            margin: auto;
        }

        .slide {
            .option_item_wrapper {
                &>div {
                    display: none;
                }
                .option__item {
                    // margin-bottom: 0;
                    // padding-bottom: 0;
                    display: flex;
                }
              
            }
        }
        .option_item_wrapper{
            display: flex ;
        }

        .option__item--slider {
            flex: 1;
        }

        .option_nav{
            opacity: 1;
        }

        .selected {
            .option_item_wrapper {
                .option__item {
                    display: grid;
                }
            }
        }
    }
}


