@import 'mixin.scss';
.field_delete__wrapper{
    position: relative;
   @include devices(mobileandtablet){
    position: absolute;
    top: 1;
    right: 16px;
    white-space: break-spaces;
   }
}
.field_delete{
    position: absolute;
    background-color: var(--grey-100);
    width: 180px;
    font-size: var(--xs-text);
    top: 22px;
    right: -17px;
    border-radius: var(--xs-radius);
    color: var(--white-60);
    padding: var(--xs-spacing);
    z-index: 3;
    line-height: 1.3em;
    header{
        font-weight: bold;
        color: var(--white-100);
        padding-bottom: var(--xxs-spacing);
    }
    span{
        cursor: pointer;
        user-select: none;
        font-weight: bold;
        color: var(--white-100);
        display: inline-block;
        padding: var(--xxs-spacing) var(--xxs-spacing) 0 0;

        &.delete{
            color: var(--red-100);
        }
    }
  }