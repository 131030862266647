// .button__block--footer{
//   padding:0;
//   .button--next{
//       // background: transparent !important;
//   }
//   .previous{
//     background: transparent !important;
//     width: min-content;
//     transition: all .2s;
//     padding: 16px 16px;
//     opacity: 0.7;
//     &:hover{
//       transition: all .2s;
//       opacity: 1;
//       box-shadow: none;
//     }
//   }
//   button{
//     &.button--next{
      
//     }
//     &.button--submit, &.button--next{
//       transition: all .2s;
//       border-radius: 12px !important;
//       border: 3px solid rgba(0, 0, 0, .7) !important;
//       box-shadow:  8px 8px 0 rgba(0, 0, 0, 1);
//       margin-bottom: 16px;
//       &:hover{
//         transition: all .5s;
//         box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
//         margin-bottom: 8px;
//       // margin: 0 36px 16px 0;
//       }
//     }

//   }
//   //button on editor
//   input {
//     border-radius: 2px;
//     box-shadow: 0 0 0 rgba(0, 0, 0, 0);
//     // padding: 20px 40px;
//     text-align: center;
//     // font-size: 20px;
//   }

// }
@import '../mixin.scss';

@keyframes sideImageAnimation_prism{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes labelAnimation_prism{
  0% { 
      opacity:0; 
  }
  10% { 
      opacity:0; 
  }
  100% { 
      opacity:1; 
  }
}

@keyframes inputAnimation_prism{
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  10% {

    opacity: 0;
    transform: scale(.9);
  }
  35% {
    opacity: 1;
    transform: scale(1.05);
  }
  45% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.formstyle__prism{
  //error
  .block_error {
    div {
      border-radius: 8px;
      animation: prismError 0.25s ease-in-out; 
    }
  }
  @keyframes prismError {
    0%{
      transform: scale(.9) rotate(10deg);
    }
    20% {
      transform: scale(1.2) rotate(0);
    }
    25% {
        transform: scale(1) rotate(0);
      }
    100% {
      transform: scale(1) rotate(0);
    }
  }
  // fields styles
  .item__voucher{
    border-radius: 8px;
    border: 2px solid;
    border-bottom: 5px solid;
  }
  .item__legal{
    // border: 2px solid;
    border-radius: 0;
    border-bottom: 3px solid;
  }
  .option__item--slider{
    border: none;
    border-bottom: 3px solid;
    transition: all .2s;
    opacity: 1;
    &:hover, &:focus{
      transition: all .2s;
      border-bottom: 5px solid;
      opacity: 1;
    }
  }
  .option_item{
    padding: var(--xxs-spacing);
    margin-bottom: var(--xxs-spacing);
    border-radius: 0;
    transition: all .2s;
    opacity: .8;
    border-bottom: 1px solid;
    box-shadow:  0px 2px 0;
    &:hover, &:focus{
      transition: all .2s;
      opacity: 1;
    }
  }
  .option_item_selected{
    opacity: 1;
    border: 2px solid;
    box-shadow:  0px 4px 0;
    margin-bottom: 12px;
  }
  .field__type_scale{
    padding: 14px;
    box-shadow:  4px 4px 0 transparent;
    opacity: 1;
    border: 3px solid transparent;
    
    &:hover{
      transition: all .2s;
      box-shadow:  4px 4px 0;
      border: 3px solid;
    }
  }
  .field__type_scale_empty{
    background-color: transparent !important;

  }
  .field__type_scale_selected{
      box-shadow:  4px 4px 0;
      transition: all .2s;
    border: 3px solid;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .field__type_rating{
    svg{
      border: 3px solid;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      border-radius: 12px;
      padding:4px;
      box-shadow:  4px 4px 0;
      transition: all .25s;
      &:hover{
        transition: all .25s;
        box-shadow: 2px 2px 0;
      }
    }
    .star-empty{
      transition: all .5s;
      box-shadow:  0 0 0;
      transition: all .25s;
      border:none;
      background-color: transparent !important;
      box-shadow:  4px 4px 0 transparent;
      padding:7px;
      &:hover{
        transition: all .25s;
        box-shadow:  4px 4px 0;
        border: 3px solid;
        padding:4px;
      }
    }
  }
  .field__type_media{
    border-radius: 12px;
    &.original{
      box-shadow:  8px 8px 0 rgba(0, 0, 0, 1);

    }
  }
  .field__type_pdf{
    border-radius: 12px;
    max-width: max-content;
    border: 3px solid rgba(0, 0, 0, 1) !important;
    box-shadow:  8px 8px 0 rgba(0, 0, 0, 1);
    transition: all .5s;
    &:hover{
      box-shadow:  0 0 0 rgba(0, 0, 0, 1);
    }
  }
  .field__input__number{
    .field__input__number__controls{
      border-radius: 8px;
      bottom: 24px;
      div{
        display: flex;
        padding:4px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        border: 2px solid rgba(0, 0, 0, 0.1) !important;
        border-bottom: 4px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0 8px 8px 0;
        transition: all .5s;

        &:hover, &:focus{
          opacity: 1;
          outline: 2px solid inherit;
          transition: all .5s;
          border-color: inherit !important;

        }
        &:first-of-type{
        border-radius: 8px 0 0 8px;
        border-right: 0 !important;
        margin-right: -2px;
        &:hover, &:focus{
          border-right: 2px solid !important;
        }
        }
      }
    }
  }
  // button styles
  button.previous{
      background: transparent !important;
      width: min-content;
      transition: all .2s;
      padding: 16px 16px;
      opacity: 0.7;
      border: 0;
      cursor: pointer;
      user-select: none;

      &:hover{
        transition: all .2s;
        transform: scale(1);
        opacity: 1;
        box-shadow: none;
      }
    }
    .button--submit{

      user-select: none;
    }



    .button--submit, .button--next{
      transition: all .2s;
      border-radius: 12px !important;
      border: 3px solid rgba(0, 0, 0, 1) !important;
      box-shadow:  8px 8px 0 rgba(0, 0, 0, 1);
      margin-bottom: 16px;
      padding: 12px;
      &:hover{
        transition: all .5s;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
        margin-bottom: 8px;
      // margin: 0 36px 16px 0;
      }
    }


    // media on the left

        .container__item--content--animation-title{
        }

        &.formtype__classic{
          .container__item--media {
            div{
            max-height: 380px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
          }}
        }
        &.formtype__slideshow{
          .container__item--media {

          }
        }
        .signature__area{
          backdrop-filter: blur(16px);
          border: 3px solid black;
          box-shadow: 8px 8px 0 black;
          margin-bottom: 20px;
        }
        .signature-canvas{
          transition: all .5s;
          border-radius: 32px;
          border:none;
          
          &:hover, &:focus{
            transition: all .5s;
            opacity: 1;
          }
        }
        //fileuploader
        .dropzone{
          border: 3px solid black !important;
          box-shadow: 8px 8px 0 black;
          &:hover, &:focus{
            transition: all .5s;
            opacity: 1;
            filter: brightness(.8);
          }
        }
        // datepicker
        .interactive_block{
          .react-datepicker-wrapper {
            width: 100%;
            position: relative;
            svg {
              position: absolute;
              top:8px;
              right:0;
            }
          }

          .react-datepicker__day{
            border-bottom: 3px solid;
            width: max-content !important;
            margin: 4px auto;
            min-width: 32px !important;
          }
          .react-datepicker__day:hover{
            border-radius: 8px;
          }
          .field__input,.react-datepicker-wrapper input {
                padding: 16px 20px;
                display: flex;
                border:none;
                border-bottom: 3px solid;
                margin-bottom: 12px;
                transition: .75s all;
                // border-radius: 8px;
                backdrop-filter: blur(16px);

                    &:focus{
                        transition: .15s all;
                        border-bottom: 3px solid black !important;
                    }
                    &::placeholder{
                      opacity: .75;
                      color: inherit;
                    }
            }
        }
        .input__icon{
            svg{
                padding-right: 18px;
            }
        }
    }



