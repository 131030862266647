@import 'mixin.scss';


//Style for form static (preview only) on template page
.form_static {
    .block_static_classic,
    .block_static_slideshow {
        @include devices(tabletanddesktop) {
            // padding: 0 var(--m-spacing);
            box-sizing: border-box;
        }
    }
    
    //applicable for slideshow only / template page
    .block_static_slideshow {
        .form_page_skeleton,
        .block_slideshow_skeleton,
        .block_item .block_item_inner_container,
        .slideshow_no_img,
        .block_item_inner_container .slideshow_w_img {
            min-height: 100%;
            height: 600px;
            max-height: 600px;
        }

        @include devices(mobile) {
            .block_item_media_inner {
                img {
                    min-height: unset;
                }
            }
        }

        @include devices(tabletanddesktop) {
            .block_item_media_inner {
                // min-height: unset;
     
            }
        }

        @include devices(desktop) {
            .block_item_content {
                display: flex;
                align-items: center;
            }

            .block_item_content_slideshow_inner,
            .block_item_media {
                flex: 1;
            }

            .block_item_media_inner img,
            .block_item_inner_container .block_item_media {
                // height: 600px;
                min-height: unset;
            }

            .block_footer {
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            .block_form_page_skeleton .block_slideshow_skeleton .block_footer_container {
                position: relative;
            }
        }


        .block_logo,
        .block_footer,
        .block_footer_container {
            position: absolute;
        }


    }
    
    .static_slideshow_w_img{
        .block_item_media_inner{
        img, video{
            // height: 600px;
        }
        }
    }
    
    //applicable for classic only / template page
    .block_static_classic {
        overflow: auto;
        height: 100%;
        min-height: unset;

        .block_classic_skeleton {
            padding-bottom: 0;
        }

        .block_item_content {
            display: unset;
        }

        .block_item,
        .block_item_inner_container {
            height: auto;
            min-height: auto;
            max-height: unset;
        }

        .block_footer_container,
        .block_footer {
            position: relative;
        }
    }
}

//Style for form static (preview only) on the hub
//Doesnt content much media query because the container is static which is auto x 322px
.form_static_hub {

    .block_logo {
        padding-left: 0;
    }
    //Applicable for both mode slideshow & classic / hub
    .block_static_slideshow, .block_static_classic{
        padding: 0;
        .block_item{
            margin: unset;
            min-width: unset;
        }
        
        .block_logo{ 
            transform: scale(80%);
        }
        .block_item_inner_container{
            min-width: unset;
            // padding: 0;
            margin: unset;
        }
        .block_item_content{
            max-width: unset;
            min-width: unset;
        }
        .block_footer{
            display: none;
        }
    }

    //Applicable for classic / hub page
    .block_static_classic{
        .block_item{
            padding: var(--s-spacing) 0;
            transform: scale(80%);
            margin-top: -40px;
        }
        .block_classic_skeleton{
            margin-top: -40px;
        }
    }

   //Applicable for slideshow / hub page
    .block_static_slideshow{
        min-height: unset;
        
        .form_preview_container{
            transform: unset;     
        }
        .block_slideshow_skeleton, .block_item .block_item_inner_container, .block_item_inner_container .block_item_content{
            height: 322px;
            max-height: 322px;
        }
        .block_item_inner_container{
            transform: scale(80%);
            column-gap: 0;
        }
        
         //field specific settings
         //applicable for slider options only - slideshow / hub / slider_option
        .block_item_slider_option{
            .block_item_content_slideshow_inner{
                transform: scale(75%);
                margin-top: 40px;
            }
        }
        
         //applicable for slider options only - slideshow / hub / slider_option / with field img
        .static_slideshow_w_img{
            transform: unset;
            .block_item_content{
                transform: scale(80%);
            }
            .block_item_content_slideshow_inner{
                padding: 0;
            }
        }

    }
    



}